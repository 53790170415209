import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { IdName, PaginationData } from 'types/common-types';
import { StateController } from 'state-controller';
import { OrdersService } from 'services/orders.service';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { debounce } from 'utils/debounce';
import {
  ExternalOrderNumberForChangeOrderModalT,
  MarketplaceOrderNumberForChangeOrderModalT,
  OrderKeyForChangeOrderModalT,
} from 'services/orders.model';

export type EditOrderModalArgs = {
  page?: Page;
  order: IdName;
  productName?: string;
  productionId?: string;
  productionKey?: string;
  isOpenFromOrder?: boolean;
  externalOrderNumber?: string;
  marketplaceOrderNumber?: string;
};

export type EditOrderModalState = {
  orderId: string;
  isOpen: boolean;
  page: Page | null;
  isLoading: boolean;
  isFetching: boolean;
  productName: string;
  productionId: string;
  productionKey: string;
  isValueChanged: boolean;
  isOpenFromOrder: boolean;
  paginationOrderKey: PaginationData;
  applyChangesToAllProductions: boolean;
  paginationExternalOrderNumber: PaginationData;
  paginationMarketplaceOrderNumber: PaginationData;
  initialData: {
    orderKeyId: string;
    externalOrderNumber: string;
    marketplaceOrderNumber: string;
  };
  orderKey: {
    isLoading: boolean;
    value: OrderKeyForChangeOrderModalT | null;
    options: OrderKeyForChangeOrderModalT[];
  };
  externalOrderNumber: {
    isLoading: boolean;
    value: ExternalOrderNumberForChangeOrderModalT | null;
    options: ExternalOrderNumberForChangeOrderModalT[];
  };
  marketplaceOrderNumber: {
    isLoading: boolean;
    value: MarketplaceOrderNumberForChangeOrderModalT | null;
    options: MarketplaceOrderNumberForChangeOrderModalT[];
  };
};

const defaultState: EditOrderModalState = {
  page: null,
  orderId: '',
  isOpen: false,
  productName: '',
  isLoading: false,
  productionId: '',
  productionKey: '',
  isFetching: false,
  isValueChanged: false,
  isOpenFromOrder: false,
  initialData: {
    orderKeyId: '',
    externalOrderNumber: '',
    marketplaceOrderNumber: '',
  },
  orderKey: {
    isLoading: false,
    value: null,
    options: [],
  },
  externalOrderNumber: {
    isLoading: false,
    value: null,
    options: [],
  },
  marketplaceOrderNumber: {
    isLoading: false,
    value: null,
    options: [],
  },
  paginationOrderKey: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  paginationExternalOrderNumber: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  paginationMarketplaceOrderNumber: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  applyChangesToAllProductions: false,
};

const stateController = new StateController<EditOrderModalState>('EDIT_ORDER_MODAL', defaultState);

export class EditOrderActions {
  public static openModal({
    page,
    order,
    productName,
    productionId,
    productionKey,
    externalOrderNumber = '',
    marketplaceOrderNumber = '',
    isOpenFromOrder = false,
  }: EditOrderModalArgs) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(stateController.setState({ isFetching: true }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          page,
          productName,
          productionId,
          productionKey,
          isOpenFromOrder,
          orderId: order.id,
          isOpen: true,
          initialData: {
            orderKeyId: order.id || '',
            externalOrderNumber,
            marketplaceOrderNumber,
          },
          orderKey: {
            ...prev.orderKey,
            value: {
              id: order?.id || '',
              name: order?.name || '',
            },
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: externalOrderNumber
              ? {
                  id: externalOrderNumber,
                  name: externalOrderNumber,
                }
              : null,
          },
          marketplaceOrderNumber: {
            ...prev.marketplaceOrderNumber,
            value: marketplaceOrderNumber
              ? {
                  id: marketplaceOrderNumber,
                  name: marketplaceOrderNumber,
                }
              : null,
          },
        })),
      );
      dispatch(stateController.setState({ isFetching: false }));
    };
  }

  public static initOrderKey() {
    return async (dispatch, getState: () => AppState) => {
      const { options } = getState().production.editOrderModal.orderKey;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await OrdersService.getAllOrders({});

        const orderKeys = data.map((order) => ({
          id: order.id,
          name: order.order_key,
          marketplace_order_number: order?.marketplace_order_number,
          marketplace_order_id: order?.id,
          external_order_number: order?.external_order_number,
          external_order_id: order?.external_order_id,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationOrderKey: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            orderKey: {
              ...prev.orderKey,
              options: orderKeys,
              value: prev.orderKey.value ?? null,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initExternalOrderNumber() {
    return async (dispatch, getState: () => AppState) => {
      const { options } = getState().production.editOrderModal.externalOrderNumber;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await OrdersService.getAllOrders({ is_external_order_number: true });
        const externalOrderNumbers = data
          .map((order) => ({
            id: order.id,
            name: order.external_order_number,
            order_key: order.order_key,
            order_id: order.id,
            marketplace_order_number: order?.marketplace_order_number,
            marketplace_order_id: order?.id,
          }))
          .filter((item) => !!item.name);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationExternalOrderNumber: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              options: externalOrderNumbers,
              value: prev.externalOrderNumber.value ?? null,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initMarketplaceOrderNumber() {
    return async (dispatch, getState: () => AppState) => {
      const { options } = getState().production.editOrderModal.marketplaceOrderNumber;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            marketplaceOrderNumber: {
              ...prev.marketplaceOrderNumber,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await OrdersService.getAllOrders({ is_marketplace_order_number: true, take: 100 });

        const marketplaceOrderNumbers = data
          .map((order) => ({
            id: order.id,
            name: order.marketplace_order_number,
            order_key: order.order_key,
            order_id: order.id,
            external_order_number: order?.external_order_number,
            external_order_id: order?.external_order_id,
          }))
          .filter((item) => !!item.name);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationMarketplaceOrderNumber: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            marketplaceOrderNumber: {
              ...prev.marketplaceOrderNumber,
              options: marketplaceOrderNumbers,
              value: prev.marketplaceOrderNumber.value ?? null,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            marketplaceOrderNumber: {
              ...prev.marketplaceOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchOrderKey(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationOrderKey: {
              ...prev.paginationOrderKey,
              next: 0,
            },
          })),
        );
      }
      const { paginationOrderKey } = getState().production.editOrderModal;
      if (isScroll && paginationOrderKey.currentPage >= paginationOrderKey.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { meta, data } = await OrdersService.getAllOrders({
            search: value.trim(),
            skip: paginationOrderKey.next || undefined,
            take: paginationOrderKey.perPage,
          });
          const orderKeys = data.map((order) => ({
            id: order.id,
            name: order.order_key,
            marketplace_order_number: order?.marketplace_order_number,
            marketplace_order_id: order?.id,
            external_order_number: order?.external_order_number,
            external_order_id: order?.external_order_id,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              orderKey: {
                ...prev.orderKey,
                options: isScroll ? [...prev.orderKey.options, ...orderKeys] : orderKeys,
                isLoading: false,
              },
              paginationOrderKey: {
                ...prev.paginationOrderKey,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchExternalOrderNumber(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationExternalOrderNumber: {
              ...prev.paginationExternalOrderNumber,
              next: 0,
            },
          })),
        );
      }
      const { paginationExternalOrderNumber } = getState().production.editOrderModal;
      if (isScroll && paginationExternalOrderNumber.currentPage >= paginationExternalOrderNumber.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { meta, data } = await OrdersService.getAllOrders({
            search: value.trim(),
            skip: paginationExternalOrderNumber.next || undefined,
            take: paginationExternalOrderNumber.perPage,
            is_external_order_number: true,
          });
          const externalOrderNumbers = data
            .map((order) => ({
              id: order.id,
              name: order.external_order_number,
              order_key: order.order_key,
              order_id: order.id,
              marketplace_order_number: order?.marketplace_order_number,
              marketplace_order_id: order?.id,
            }))
            .filter((item) => !!item.name);

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              externalOrderNumber: {
                ...prev.externalOrderNumber,
                options: isScroll ? [...prev.externalOrderNumber.options, ...externalOrderNumbers] : externalOrderNumbers,
                isLoading: false,
              },
              paginationExternalOrderNumber: {
                ...prev.paginationExternalOrderNumber,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchMarketplaceOrderNumber(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationMarketplaceOrderNumber: {
              ...prev.paginationMarketplaceOrderNumber,
              next: 0,
            },
          })),
        );
      }
      const { paginationMarketplaceOrderNumber } = getState().production.editOrderModal;
      if (isScroll && paginationMarketplaceOrderNumber.currentPage >= paginationMarketplaceOrderNumber.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            marketplaceOrderNumber: {
              ...prev.marketplaceOrderNumber,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { meta, data } = await OrdersService.getAllOrders({
            search: value.trim(),
            skip: paginationMarketplaceOrderNumber.next || undefined,
            take: paginationMarketplaceOrderNumber.perPage,
            is_marketplace_order_number: true,
          });
          const marketplaceOrderNumbers = data
            .map((order) => ({
              id: order.id,
              name: order.marketplace_order_number,
              order_key: order.order_key,
              order_id: order.id,
              external_order_number: order?.external_order_number,
              external_order_id: order?.id,
            }))
            .filter((item) => !!item.name);

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              marketplaceOrderNumber: {
                ...prev.marketplaceOrderNumber,
                options: isScroll
                  ? [...prev.marketplaceOrderNumber.options, ...marketplaceOrderNumbers]
                  : marketplaceOrderNumbers,
                isLoading: false,
              },
              paginationMarketplaceOrderNumber: {
                ...prev.paginationMarketplaceOrderNumber,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            marketplaceOrderNumber: {
              ...prev.marketplaceOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static onValueOrderKeyChange(value: EditOrderModalState['orderKey']['value']) {
    return (dispatch) => {
      const isExternalOrderNumberExist = value?.external_order_id && value?.external_order_number;
      const isMarketplaceOrderNumberExist = value?.marketplace_order_id && value?.marketplace_order_number;

      const chosenExternalOrderNumber = {
        id: value?.external_order_id,
        name: value?.external_order_number,
      } as ExternalOrderNumberForChangeOrderModalT;

      const chosenMarketplaceOrderNumber = {
        id: value?.marketplace_order_id,
        name: value?.marketplace_order_number,
      } as MarketplaceOrderNumberForChangeOrderModalT;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: {
            ...prev.orderKey,
            value,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: isExternalOrderNumberExist ? chosenExternalOrderNumber : null,
          },
          marketplaceOrderNumber: {
            ...prev.marketplaceOrderNumber,
            value: isMarketplaceOrderNumberExist ? chosenMarketplaceOrderNumber : null,
          },
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static onValueExternalOrderNumberChange(value: EditOrderModalState['externalOrderNumber']['value']) {
    return (dispatch) => {
      const isOrderKeyExist = value?.order_id && value?.order_key;
      const isMarketplaceOrderNumberExist = value?.marketplace_order_id && value?.marketplace_order_number;

      const chosenOrderKey = {
        id: value?.order_id,
        name: value?.order_key,
      } as OrderKeyForChangeOrderModalT;

      const chosenMarketplaceOrderNumber = {
        id: value?.marketplace_order_id,
        name: value?.marketplace_order_number,
      } as MarketplaceOrderNumberForChangeOrderModalT;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value,
          },
          orderKey: {
            ...prev.orderKey,
            value: isOrderKeyExist ? chosenOrderKey : null,
          },
          marketplaceOrderNumber: {
            ...prev.marketplaceOrderNumber,
            value: isMarketplaceOrderNumberExist ? chosenMarketplaceOrderNumber : null,
          },
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static onValueMarketPlaceOrderNumberChange(value: EditOrderModalState['marketplaceOrderNumber']['value']) {
    return (dispatch) => {
      const isExternalOrderNumberExist = value?.external_order_id && value?.external_order_number;
      const isOrderKeyExist = value?.order_id && value?.order_key;

      const chosenExternalOrderNumber = {
        id: value?.external_order_id,
        name: value?.external_order_number,
      } as ExternalOrderNumberForChangeOrderModalT;

      const chosenOrderKey = {
        id: value?.order_id,
        name: value?.order_key,
      } as OrderKeyForChangeOrderModalT;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          marketplaceOrderNumber: {
            ...prev.marketplaceOrderNumber,
            value,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: isExternalOrderNumberExist ? chosenExternalOrderNumber : null,
          },
          orderKey: {
            ...prev.orderKey,
            value: isOrderKeyExist ? chosenOrderKey : null,
          },
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { productionId, orderKey, initialData, page, orderId, applyChangesToAllProductions } =
          getState().production.editOrderModal;

        if (initialData.orderKeyId !== orderKey?.value?.id) {
          if (orderKey?.value?.id) {
            const body = {
              current_order_id: orderId,
              new_order_id: orderKey?.value?.id,
            };

            const params = {
              change_all_productions: applyChangesToAllProductions,
            };

            await ProductionWorkflowService.updateProductionWorkflowDetailsByOrder(productionId, body, params);
          }
        }

        if (page === Page.InfoDropdownWorkflow) {
          await loadData(productionId);
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        if (page === Page.InfoDropdownProduction) {
          await loadData(productionId);

          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
              resetOpenedProductionGroups: true,
            }),
          );
        }

        if (page === Page.Production) {
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
              resetOpenedProductionGroups: true,
            }),
          );
        }

        if (page === Page.Workflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        dispatch(EditOrderActions.closeModal());
        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static switchShowWarning() {
    return async (dispatch, getState: () => AppState) => {
      const { orderKey, externalOrderNumber, marketplaceOrderNumber, initialData } = getState().production.editOrderModal;
      if (
        initialData.orderKeyId !== orderKey?.value?.id ||
        initialData.externalOrderNumber !== externalOrderNumber?.value?.id ||
        initialData.marketplaceOrderNumber !== marketplaceOrderNumber?.value?.id
      ) {
        dispatch(stateController.setState({ isValueChanged: true }));
      } else {
        dispatch(stateController.setState({ isValueChanged: false }));
      }
    };
  }

  public static onChangeApplyChangesToAllProductions(value: boolean) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          applyChangesToAllProductions: value,
        })),
      );
    };
  }
}

export const reducer = stateController.getReducer();
