import { MouseEvent } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { LinePosition } from 'icons/line-position';
import { DepartmentsIcon } from 'icons/departments';
import { UserShortModel } from 'services/user.model';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import Spinner from 'components/spinner/spinner';
import s from './menu-button.module.scss';

type OwnProps = {
  isOpen: boolean;
  tooltip?: string;
  isLoading: boolean;
  suggestedUsers: any;
  withHover?: boolean;
  isAvatarOnly?: boolean;
  showUserExtraData?: boolean;
  selectedUser?: Partial<UserShortModel> | null;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
};

export const MenuButton = ({
  isOpen,
  isLoading,
  tooltip,
  selectedUser,
  suggestedUsers,
  withHover = true,
  isAvatarOnly = false,
  showUserExtraData = true,
  onClick,
}: OwnProps) => {
  const isEmpty = !selectedUser || !selectedUser?.id;

  if (isLoading) {
    return (
      <div className={s.spinner_container}>
        <Spinner size={14} />
      </div>
    );
  }

  return (
    <div onClick={(event) => onClick(event)} className={suggestedUsers && s.assignable_container}>
      <Tooltip placement="top-start" title={!isEmpty ? `${selectedUser?.first_name} ${selectedUser?.last_name}` : tooltip}>
        <span>
          <AvatarCircle
            isEmpty={isEmpty}
            isActive={isOpen}
            withHover={withHover || isAvatarOnly}
            lastName={selectedUser?.last_name || ''}
            imageSrc={selectedUser?.avatar_image_url}
            firstName={selectedUser?.first_name || ''}
            className={`${isOpen ? s.selected : ''} ${s.avatar_circle}`}
          />
        </span>
      </Tooltip>
      {!isAvatarOnly ? (
        <div className={s.selected_user_container}>
          <Typography marginBottom="4px">
            {!isEmpty ? `${selectedUser.first_name} ${selectedUser.last_name}` : 'Unassigned'}
          </Typography>

          {!isEmpty && showUserExtraData ? (
            <div className={s.user_info_container}>
              <Stack flexDirection="row" gap="6px" overflow="hidden">
                <Stack flexShrink={0}>
                  <DepartmentsIcon width={16} height={16} />
                </Stack>
                <TextEllipsis maxWidth="200px" tooltipTitle={selectedUser?.department?.name || 'No info'}>
                  {selectedUser?.department?.name || 'No info'}
                </TextEllipsis>
              </Stack>
              <Stack flexDirection="row" gap="6px" overflow="hidden">
                <Stack flexShrink={0}>
                  <LinePosition width={16} height={16} />
                </Stack>
                <TextEllipsis maxWidth="350px" tooltipTitle={selectedUser?.position?.name || 'No info'}>
                  {selectedUser?.position?.name || 'No info'}
                </TextEllipsis>
              </Stack>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
