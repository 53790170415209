import { FC, ReactNode } from 'react';
import { FolderIcon } from 'icons/folder';
import { Typography } from '@mui/material';
import { IdName } from 'types/common-types';
import Button from 'components/button/button';
import Modal from 'components/ui-new/modal/modal';
import Subtitle from 'components/subtitle/subtitle';
import ModalItem from 'components/modal-item/modal-item';
import ModalBody from 'components/modal-body/modal-body';
import ModalHeader from 'components/modal-header/modal-header';
import ModalFooter from 'components/modal-footer/modal-footer';
import ModalSvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import s from './move-modal.module.scss';

type OwnProps = {
  label: string;
  isOpen: boolean;
  subTitle?: string;
  dontClose?: boolean;
  placeholder?: string;
  value: IdName | null;
  options: Array<IdName>;
  isProcessing?: boolean;
  moveButtonText?: string;
  isLoadingOptions?: boolean;
  isMoveButtonDisabled?: boolean;
  warning?: ReactNode | undefined;
  handleMove: () => void;
  onInputBlur?: () => void;
  onChangeValue: (value: IdName) => void;
  closeModal: (modalName?: string) => void;
  loadMoreOptions: (value?: string, isScroll?: boolean, withoutDelay?: boolean) => void;
};

const MoveModal: FC<OwnProps> = ({
  value,
  label,
  isOpen,
  warning,
  options,
  subTitle,
  dontClose,
  isProcessing,
  placeholder = '',
  isLoadingOptions,
  isMoveButtonDisabled,
  moveButtonText = 'Move to',
  closeModal,
  handleMove,
  onInputBlur,
  onChangeValue,
  loadMoreOptions,
}) => {
  const onCloseModal = () => closeModal('moveModal');

  useDisableDocumentScroll(isOpen, 'moveModal');

  const handleOnInputChange = (v: string) => {
    loadMoreOptions(v, false);
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal} PaperProps={{ className: s.modal }}>
      <ModalHeader withCloseButton={false} witohutShadow size="newMedium" onClose={onCloseModal} className={s.modal_header}>
        <div className={s.header}>
          <Subtitle
            text="Move to"
            fontSize="large"
            icon={<ModalSvgWrapper containerSize={38} iconSize={20} backgroundColor="#DBE9FF" SvgIcon={FolderIcon} />}
          />
          <Typography fontWeight="400" fontSize={14} lineHeight="21px">
            {subTitle ?? `Select a ${label} from the list`}
          </Typography>
        </div>
      </ModalHeader>

      <ModalBody size="newMedium" className={s.body}>
        {warning && warning}

        <ModalItem
          label={label}
          value={value}
          options={options}
          loadDataAfterFocus
          disabled={isProcessing}
          placeholder={placeholder}
          loading={isLoadingOptions}
          onBlur={onInputBlur}
          loadMoreOptions={loadMoreOptions}
          onAutocompleteChange={(e, val) => onChangeValue(val)}
          onTextFieldChange={(e) => handleOnInputChange(e.target.value)}
        />
      </ModalBody>

      <ModalFooter size="medium" className={s.footer}>
        <Button variant="contained" color="secondary" size="XL" fullWidth onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          size="XL"
          color="primary"
          variant="contained"
          loading={isProcessing}
          disabled={isMoveButtonDisabled}
          onClick={() => {
            handleMove();

            if (!dontClose) onCloseModal();
          }}
        >
          {moveButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MoveModal;
