import { Tooltip } from '@mui/material';

const ArrayIdNameCell = ({ value }: { value: { id: string; name: string }[] }) => {
  const names = Array.isArray(value) ? value.map((item) => item.name).filter(Boolean) : [];
  const joinedNames = names.join(', ');

  return (
    <Tooltip placement="top" title={names.length > 1 ? joinedNames : ''}>
      <div style={{ display: 'inline' }}>{joinedNames}</div>
    </Tooltip>
  );
};

export default ArrayIdNameCell;
