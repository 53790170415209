import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import {
  DepartmentItem,
  Actions as DepartmentsActions,
  DepartmentItem as DepartmentItemType,
} from 'pages/departments/departments.controller';
import { Actions as MultiselectActions } from 'pages/products/multiselect.controller';
import { PositionSlotByDepartmentId as PositionItemType } from 'services/position-slots.model';
import PositionItem from 'pages/departments/modules/department-data-items/components/position-item';
import SubDepartment from 'pages/departments/modules/department-data-items/components/sub-department';
import { Actions as AddPositionModalActions } from 'components/add-position-modal/add-position-modal.controller';
import s from './department-data-items.module.scss';

type StateProps = {
  isMultiselect: boolean;
  renameModeItemId: string;
  lastAddedItemId: string;
  positions: PositionItemType[];
  departments: DepartmentItem[];
  selectedPositionIds: string[];
  selectedDepartmentIds: string[];
};

type DispatchProps = {
  closeRenameMode: () => void;
  selectPosition: (id: string) => void;
  selectDepartment: (id: string) => void;
  deselectPosition: (id: string) => void;
  deselectDepartment: (id: string) => void;
  addPositionModalOpen: (item?: PositionItemType) => void;
  createNewDepartment: (name: string, parentId: string) => void;
  renameDepartment: (departmentId: string, newName: string) => void;
  openMoveModal: (item?: PositionItemType | DepartmentItemType) => void;
  openDeleteConfirmationModal: (item?: PositionItemType | DepartmentItemType) => void;
};

type Props = StateProps & DispatchProps;

const DepartmentDataItems: FC<Props> = ({
  positions,
  departments,
  isMultiselect,
  lastAddedItemId,
  renameModeItemId,
  selectedPositionIds,
  selectedDepartmentIds,
  openMoveModal,
  selectPosition,
  closeRenameMode,
  renameDepartment,
  selectDepartment,
  deselectPosition,
  deselectDepartment,
  createNewDepartment,
  addPositionModalOpen,
  openDeleteConfirmationModal,
}) => {
  const newElement = document.getElementById('dep-new');
  const targetElement = document.getElementById(`dep-${lastAddedItemId}`);

  useEffect(() => {
    if (targetElement) {
      const elementRect = targetElement.getBoundingClientRect();
      window.scrollTo({
        top: elementRect.top + window.scrollY - 150,
        behavior: 'smooth',
      });
    }
  }, [targetElement, lastAddedItemId]);

  useEffect(() => {
    if (newElement) {
      const elementRect = newElement.getBoundingClientRect();
      window.scrollTo({
        top: elementRect.top + window.scrollY - 150,
        behavior: 'smooth',
      });
    }
  }, [newElement]);

  return (
    <div className={s.container}>
      {positions.map((item, index) => {
        const isSelected = selectedPositionIds.some((i) => i === item.id);

        return (
          <PositionItem
            key={index}
            data={item}
            isSelected={isSelected}
            lastAddedItemId={lastAddedItemId}
            isMultiselectMode={isMultiselect}
            onMoveItem={openMoveModal}
            selectElement={selectPosition}
            onEditItem={addPositionModalOpen}
            deselectElement={deselectPosition}
            onDeleteItem={openDeleteConfirmationModal}
          />
        );
      })}
      {departments.map((item, index) => {
        const isSelected = selectedDepartmentIds.some((i) => i === item.id);

        return (
          <SubDepartment
            key={index}
            data={item}
            isSelected={isSelected}
            lastAddedItemId={lastAddedItemId}
            isMultiselectMode={isMultiselect}
            renameModeItemId={renameModeItemId}
            onMoveItem={openMoveModal}
            selectElement={selectDepartment}
            closeRenameMode={closeRenameMode}
            renameDepartment={renameDepartment}
            onCreateClick={createNewDepartment}
            deselectElement={deselectDepartment}
            onDeleteItem={openDeleteConfirmationModal}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  positions: state.departments.positions,
  departments: state.departments.departments,
  lastAddedItemId: state.departments.lastAddedItemId,
  renameModeItemId: state.departments.renameModeItemId,
  isMultiselect: state.products.multiselect.isMultiselect,
  selectedPositionIds: state.products.multiselect.selectedPositionIds,
  selectedDepartmentIds: state.products.multiselect.selectedDepartmentIds,
});

const mapDispatchToProps: DispatchProps = {
  openMoveModal: DepartmentsActions.openMoveModal,
  selectPosition: MultiselectActions.selectPosition,
  closeRenameMode: DepartmentsActions.closeRenameMode,
  selectDepartment: MultiselectActions.selectDepartment,
  deselectPosition: MultiselectActions.deselectPosition,
  renameDepartment: DepartmentsActions.renameDepartment,
  addPositionModalOpen: AddPositionModalActions.openModal,
  deselectDepartment: MultiselectActions.deselectDepartment,
  createNewDepartment: DepartmentsActions.createNewDepartment,
  openDeleteConfirmationModal: DepartmentsActions.openDeleteConfirmationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDataItems);
