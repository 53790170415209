import { IdName } from 'types/common-types';

export const mergeDepartmentsAndPositions = (data: IdName[]) => {
  if (!data.length) return null;

  const uniqueNames = Array.from(new Set(data.map((item) => item.name)));

  return {
    id: data[0]?.id || 'id',
    name: uniqueNames.join(', '),
  };
};

export const mergePositionSlots = (slots, key: 'department' | 'position_type') => {
  if (!slots.length) return null;

  const uniqueNames = Array.from(new Set(slots.map((item) => item.position_slot[key].name)));

  return {
    id: slots[0]?.position_slot[key].id || 'id',
    name: uniqueNames.join(', '),
  };
};
