import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { IdName } from 'types/common-types';
import { UserShortModel, User } from 'services/user.model';
import ShowMoreSlots from 'components/ui-new/multiple-assignee-selector/components/show-more-slots/show-more-slots';
import DropdownUserSearchSelector, {
  UNASSIGNED,
} from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import s from './multiple-assignee-selector.module.scss';

export type MultipleAssigneeSelectorSlotsT = {
  id: string;
  tooltip?: string;
  last_name: string;
  first_name: string;
  assignedUserId: string;
  responsibilityId: string;
  avatar_image_url: string;
  position: IdName | undefined;
  department: IdName | undefined;
};

type OwnProps = {
  users?: User[];
  withHover?: boolean;
  isAvatarOnly?: boolean;
  isBiggerPopup?: boolean;
  isEditPermitted?: boolean;
  showUserExtraData?: boolean;
  enableStopPropagation?: boolean;
  slots: MultipleAssigneeSelectorSlotsT[];
  onClose?: VoidFunction;
  assignUser: (user: UserShortModel, slot_id: string, slot: MultipleAssigneeSelectorSlotsT) => void;
  unassignUser: (prevUser: UserShortModel, slot_id: string, slot: MultipleAssigneeSelectorSlotsT) => void;
};

const MultipleAssigneeSelector: FC<OwnProps> = ({
  users,
  withHover,
  slots = [],
  isAvatarOnly,
  isBiggerPopup,
  showUserExtraData,
  enableStopPropagation,
  isEditPermitted = true,
  onClose,
  assignUser,
  unassignUser,
}) => {
  const assignedUsersIds = slots.map((item) => item.assignedUserId).filter((id) => id !== undefined);

  const shownElements = useMemo(() => {
    if (slots.length > 4) {
      return slots.slice(0, 4);
    }
    return slots;
  }, [slots]);

  const getFormattedUser = (user: MultipleAssigneeSelectorSlotsT): UserShortModel => {
    return {
      id: user.assignedUserId,
      position: user.position,
      last_name: user.last_name,
      department: user.department,
      first_name: user.first_name,
      avatar_image_url: user.avatar_image_url,
    };
  };

  return (
    <div className={s.assigned_users_container}>
      {shownElements.length
        ? shownElements.map((slot, index) => {
            return (
              <Box
                className={s.avatar_container}
                sx={{
                  marginRight: shownElements.length - 1 === index ? '' : '-8px',
                  zIndex: index,
                }}
                key={slot.id}
              >
                <DropdownUserSearchSelector
                  users={users}
                  onClose={onClose}
                  withHover={withHover}
                  isTaskPerformerSelect
                  tooltip={slot.tooltip}
                  isAvatarOnly={isAvatarOnly}
                  isBiggerPopup={isBiggerPopup}
                  isEditPermitted={isEditPermitted}
                  value={slot.assignedUserId ?? ''}
                  assignedUsersIds={assignedUsersIds}
                  showUserExtraData={showUserExtraData}
                  responsibilityId={slot.responsibilityId}
                  enableStopPropagation={enableStopPropagation}
                  currentlySelectedUser={getFormattedUser(slot)}
                  onSelect={(value, prev, selected) => {
                    if (value === UNASSIGNED && prev) {
                      unassignUser(prev, slot.id, slot);
                      return;
                    }

                    if (selected) assignUser(selected, slot.id, slot);
                  }}
                />
              </Box>
            );
          })
        : null}
      {slots.length >= 5 && <ShowMoreSlots slots={slots.slice(4)} />}
    </div>
  );
};

export default MultipleAssigneeSelector;
