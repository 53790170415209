/* eslint-disable consistent-return */
import { useEffect } from 'react';
//! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
// import { useChatWidget } from 'hooks/use-chat-widget';

const useDisableDocumentScroll = (isScrollDisabled: boolean, modalKey: string) => {
  // modalKey must be unique!

  // === Keys already used: ===
  // modal
  // moveModal
  // mediaModal
  // infoDropdown
  // dropdownMenu
  // editOrderModal
  // editClientModal
  // editVariantModal
  // addPositionModal
  // prioritySelector
  // newProductionModal
  // textEditableSelect
  // productsLaunchModal
  // printLabelWithQrModal
  // addParameterTemplateModal
  // dropdownUserSearchSelector
  // textEditableWithAutosuggest
  // taskTemplateResponsibilityModal

  // Need to use localStorage instead of sessionStorage because sessionStorage has separate state per tab.
  useEffect(() => {
    const scrollStateObj = JSON.parse(localStorage.getItem('scrollStateObj') || '{}');
    scrollStateObj[modalKey] = isScrollDisabled;

    localStorage.setItem('scrollStateObj', JSON.stringify(scrollStateObj));

    const isScrollActive = document.documentElement.scrollHeight > window.innerHeight;
    if (!isScrollActive) return;

    const { userAgent } = navigator;

    let scrollbarWidth: number;
    if (userAgent.indexOf('Firefox') > -1) {
      scrollbarWidth = 17.26;
    } else {
      scrollbarWidth = 2.66;
    }

    const toggleScroll = (shouldBlock: boolean) => {
      //! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
      // const translateValue = shouldBlock ? scrollbarWidth : 0;
      document.documentElement.style.overflow = shouldBlock ? 'hidden' : '';
      document.documentElement.style.paddingRight = shouldBlock ? `${scrollbarWidth}px` : '';
      //! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
      // useChatWidget().setTransform(`translateX(-${translateValue}px)`);
    };

    const shouldBlockScroll = Object.values(scrollStateObj).some((value) => value === true);
    toggleScroll(shouldBlockScroll);

    return () => {
      const updatedScrollStateObj = JSON.parse(localStorage.getItem('scrollStateObj') || '{}');
      updatedScrollStateObj[modalKey] = false;

      localStorage.setItem('scrollStateObj', JSON.stringify(updatedScrollStateObj));

      const shouldBlockScrollAfterUnmount = Object.values(updatedScrollStateObj).some((value) => value === true);
      toggleScroll(shouldBlockScrollAfterUnmount);
    };
  }, [isScrollDisabled, modalKey]);
};

export default useDisableDocumentScroll;
