import { FC } from 'react';
import { UserShortModel } from 'services/user.model';
import OptionItem from 'components/ui-new/dropdown-user-search-selector/components/option-item/option-item';
import { mergeDepartmentsAndPositions } from 'components/ui-new/dropdown-user-search-selector/components/list-for-task/helpers';

type OwnProps = {
  value: string;
  keyword: string;
  isBiggerPopup?: boolean;
  users: UserShortModel[];
  onOptionClick: (userId: string, user: UserShortModel) => void;
};

export const ListForWorkflow: FC<OwnProps> = ({ users, value, keyword, isBiggerPopup, onOptionClick }) => {
  return (
    <div>
      {Boolean(users.length) && (
        <div>
          <div>
            {users.map((user) => (
              <OptionItem
                key={user.id}
                keyword={keyword}
                position={Array.isArray(user.positions) ? mergeDepartmentsAndPositions(user.positions) : user.position}
                last_name={user.last_name}
                first_name={user.first_name}
                selected={value === user.id}
                department={Array.isArray(user.departments) ? mergeDepartmentsAndPositions(user.departments) : user.department}
                isBiggerPopup={isBiggerPopup}
                avatarImageSource={user.avatar_image_url}
                onClick={() => onOptionClick(user.id, user)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
