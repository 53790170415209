import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';

const AssignedAtCell = ({ value }: { value: { id: string; name: string }[] }) => {
  const formattedDates = Array.isArray(value)
    ? value
        .map((item) => {
          const formattedDate = dayjs(item.name).isValid() ? dayjs(item.name).format('DD.MM.YYYY HH:mm:ss') : null;
          return formattedDate;
        })
        .filter(Boolean)
    : [];

  const joinedDates = formattedDates.join(', ');

  return (
    <Tooltip placement="top" title={formattedDates.length > 1 ? joinedDates : ''}>
      <div style={{ display: 'inline' }}>{joinedDates}</div>
    </Tooltip>
  );
};

export default AssignedAtCell;
