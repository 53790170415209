import { UNASSIGNED } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import {
  ManageAssignSlotIdsArgs,
  MapAssignAtArgs,
  MapAssigneeDepartmentsArgs,
  MapAssigneePositionsArgs,
  UpdatedTaskResponsibilitiesValuesReturnT,
  UpdateTasksParamsArgs,
} from 'pages/tasks/types/function-types';
import { SelectedTaskSlotT, SelectedTaskT, TaskTableStateT } from 'pages/tasks/types/types';
import { CreateUpdateTaskResponse } from 'services/production-task.model';
import { AssignResponsibleToTasksBodyArgs } from 'services/task-table.model';
import { IdName } from 'types/common-types';

export const getAllTasksOnTheScreen = (tasks: TaskTableStateT[]): SelectedTaskT[] => {
  if (!Array.isArray(tasks) || tasks.length === 0) {
    return [];
  }

  return tasks.map((task) => ({
    id: task.id,
    departmentIds: task.department_ids,
    slots: task.assignee.map((assignee) => ({
      slot_id: assignee.slot_id,
      task_responsibility_id: assignee.task_responsibility_id,
    })),
  }));
};

export const getAllTasksSlotIdsOnTheScreen = (tasks: SelectedTaskT[]): string[] => {
  if (!Array.isArray(tasks) || tasks.length === 0) {
    return [];
  }

  const allSlots = tasks.reduce((acc, task) => {
    acc.push(...task.slots);
    return acc;
  }, [] as SelectedTaskSlotT[]);

  return allSlots.map((slot) => slot.slot_id);
};

export const manageAssignSlotIds = ({ assignSlotIds, taskSlotIds, isSelected }: ManageAssignSlotIdsArgs): string[] => {
  const assignSlotSet = new Set(assignSlotIds);

  if (isSelected) {
    taskSlotIds.forEach(assignSlotSet.delete, assignSlotSet);
  } else {
    taskSlotIds.forEach(assignSlotSet.add, assignSlotSet);
  }

  return Array.from(assignSlotSet);
};

export const getResponsibleId = (responsibleId: string | undefined) => {
  if (responsibleId === UNASSIGNED) return { responsibleId: null };
  if (responsibleId) return { responsibleId };
  return null;
};

export const prepareRequestBodyForBulkAssignUsers = (
  performers: Record<string, string | null>,
  tasks: SelectedTaskT[],
): AssignResponsibleToTasksBodyArgs[] => {
  const userIds = Object.values(performers);

  return userIds.map((userId, index) => {
    const taskSlotIds = tasks.map((task) => task.slots[index]?.slot_id);

    return {
      user_id: userId,
      task_slot_ids: taskSlotIds,
    };
  });
};

export const updateTasksFromWsResponse = ({ id, slotId, user, tasks }: UpdateTasksParamsArgs) => {
  const currentAssignAt = new Date().toISOString();
  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id !== id) {
        return task;
      }

      return {
        ...task,
        assignee: task.assignee.map((assignee) => {
          if (assignee.slot_id === slotId) {
            return {
              ...assignee,
              taskAssignment: user ? { user } : null,
            };
          }
          return assignee;
        }),
        task_slots: task.task_slots?.map((slot) => {
          if (slot.id === slotId) {
            return {
              ...slot,
              ...(user ? { task_assignment_id: user.id } : {}),
            };
          }
          return slot;
        }),
        is_assignment_in_progress: false,
        assignee_position: task.assignee_position?.map((position) => {
          if (position.id === slotId) {
            return { id: slotId, name: user?.position[0] || '' };
          }
          return position;
        }),
        assignee_department: task.assignee_department?.map((department) => {
          if (department.id === slotId) {
            return { id: slotId, name: user?.department[0] || '' };
          }
          return department;
        }),
        assigned_at: task.assigned_at?.map((assigned) => {
          if (assigned.id === slotId) {
            return {
              id: slotId,
              name: user ? currentAssignAt : ' ',
            };
          }
          return assigned;
        }),
      };
    }),
  };
};

export const extractUpdatedTaskResponsibilitiesValues = (data: CreateUpdateTaskResponse) => {
  return data.responsibilities.reduce<UpdatedTaskResponsibilitiesValuesReturnT>(
    (acc, responsibility) => {
      responsibility.taskSlots.forEach((taskSlot) => {
        const { taskAssignment } = taskSlot;

        if (taskAssignment) {
          const { user } = taskAssignment;
          const positionSlot = user?.user_position_slots?.[0]?.position_slot;

          acc.positions.push({
            id: taskSlot.id,
            name: positionSlot?.position_type?.name || '',
          });
          acc.departments.push({
            id: taskSlot.id,
            name: positionSlot?.department?.name || '',
          });
          acc.assignAt.push({
            id: taskSlot.id,
            name: taskAssignment.assign_at || '',
          });
        }
      });

      return acc;
    },
    {
      positions: [],
      departments: [],
      assignAt: [],
    },
  );
};

export const mapAssigneePositions = ({ assignee, positions = [] }: MapAssigneePositionsArgs): IdName[] => {
  return assignee.map((slot) => {
    const position = slot.taskAssignment ? positions.shift() || '' : '';

    return {
      id: slot.slot_id,
      name: position,
    };
  });
};

export const mapAssigneeDepartments = ({ assignee, departments = [] }: MapAssigneeDepartmentsArgs): IdName[] => {
  return assignee.map((slot) => {
    const department = slot.taskAssignment ? departments.shift() || '' : '';

    return {
      id: slot.slot_id,
      name: department,
    };
  });
};

export const mapAssignAt = ({ assignee, assigned_ats = [] }: MapAssignAtArgs): IdName[] => {
  return assignee.map((slot) => {
    const assigned_at = slot.taskAssignment ? assigned_ats.shift() || '' : '';

    return {
      id: slot.slot_id,
      name: assigned_at,
    };
  });
};
