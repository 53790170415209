import { baseAxiosInstance } from 'axios-config';
import {
  WorkflowTaskTemplate,
  CreateWorkflowTaskTemplateData,
  UpdateWorkflowTaskTemplateData,
} from 'services/workflow-task-templates.model';
import { PaginatedResponse } from 'types/common-types';
import { buildQueryString } from 'utils/build-query-string';

export class WorkflowTaskTemplatesService {
  public static async create(body: CreateWorkflowTaskTemplateData) {
    const { data } = await baseAxiosInstance.post<WorkflowTaskTemplate>('/workflow-task-templates/create', body);

    return data;
  }

  public static async manageOrder(taskTemplates: { id: string; order: number }[]) {
    const { data } = await baseAxiosInstance.post<{ message: string }>('/workflow-task-templates/manage-order', {
      taskTemplates,
    });

    return data;
  }

  public static async update(id: string, body: UpdateWorkflowTaskTemplateData) {
    const { data } = await baseAxiosInstance.put<WorkflowTaskTemplate>(`/workflow-task-templates/update/${id}`, body);

    return data;
  }

  public static async duplicate(id: string) {
    const { data } = await baseAxiosInstance.post<WorkflowTaskTemplate>(`/workflow-task-templates/duplicate/${id}`);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<WorkflowTaskTemplate>(`/workflow-task-templates/delete/${id}`);

    return data;
  }

  public static async getAllTaskTemplateNames(search: string = '', skip: number = 0, take: number = 3) {
    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get<PaginatedResponse<string[]>>(`/workflow-task-templates/names?${queryParams}`);

    return data;
  }
}
