import { FC } from 'react';
import Spinner from 'components/spinner/spinner';
import SearchRowItem from 'components/search-row-item/search-row-item';
import s from './autosuggest-dropdown.module.scss';

type OwnProps = {
  fullWidth?: boolean;
  searchedValue: string;
  suggestedValues: string[];
  isLazyProcessing?: boolean;
  isAutosuggestVisible: boolean;
  isAutosuggestLoading: boolean;
  inputHeight: number | undefined;
  onSuggestedItemClick: (value: string) => void;
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
};

const AutosuggestDropdown: FC<OwnProps> = ({
  searchedValue,
  suggestedValues,
  inputHeight = 35,
  fullWidth = false,
  isAutosuggestVisible,
  isAutosuggestLoading,
  isLazyProcessing = false,
  onScroll,
  onSuggestedItemClick,
}) => {
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (onScroll) onScroll(event);
  };

  return (
    <div
      data-is-full-width={fullWidth}
      style={{ top: inputHeight + 4 }}
      className={s.autosuggest_section}
      data-is-visible={isAutosuggestVisible}
      data-is-lazy-processing={isLazyProcessing}
    >
      {isAutosuggestLoading && (
        <div className={s.spinner}>
          <Spinner size={20} />
        </div>
      )}
      {!isAutosuggestLoading && (
        <div className={s.items} onScroll={(event) => handleScroll(event)}>
          {suggestedValues?.length ? (
            <>
              {suggestedValues.map((name, index) => (
                <SearchRowItem
                  value={name}
                  key={`${name}-${index}`}
                  searchedValue={searchedValue}
                  onClick={(value) => onSuggestedItemClick(value)}
                />
              ))}
            </>
          ) : (
            <div className={s.no_results}>No option</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutosuggestDropdown;
