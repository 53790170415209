import { TabRightIcon } from 'icons/tab-right';
import Checkbox from 'components/checkbox/checkbox';
import { ReactNode, useRef, useState } from 'react';
import { bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { Chip, ClickAwayListener, FormControlLabel, Grow, Paper, Popper } from '@mui/material';

import { FailReasonsModel } from 'services/production-task.model';
import s from './dropdown-reason.module.scss';

type OptionType = {
  disabled?: boolean;
  [key: string]: any;
  onClick?: () => void;
};
type Props = {
  values: FailReasonsModel[];
  label?: string;
  icon?: ReactNode;
  chevron?: boolean;
  disabled?: boolean;
  menuTitle?: string;
  indicator?: boolean;
  options: Array<FailReasonsModel & OptionType>;
  headerCheckBoxes?: FailReasonsModel[];
  hasBackgroundWhenSelected?: boolean;
  onChange: (selectedItem: FailReasonsModel) => void;
};

export default function DropdownReason({
  icon,
  label,
  values,
  options,
  disabled,
  menuTitle,
  chevron = true,
  headerCheckBoxes,
  hasBackgroundWhenSelected,
  onChange,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const popupState = usePopupState({ variant: 'popper', popupId: `reason-selector` });
  popupState.close = () => {
    setIsOpen(false);
  };

  return (
    <div ref={rootRef} {...bindTrigger(popupState)}>
      <div
        className={s.container}
        data-disbled={disabled}
        onClick={() => setIsOpen(true)}
        data-selected={hasBackgroundWhenSelected && values.length !== 0}
      >
        {icon && <div className={s.icon_container}>{icon}</div>}

        {values.length > 0 ? (
          <div className={s.label_container}>
            {values.map((value) => (
              <Chip className={s.label_item} key={value.id} label={value.en_reason} onDelete={() => onChange(value)} />
            ))}
          </div>
        ) : (
          <div className={s.label_container}>{label}</div>
        )}

        {chevron && <TabRightIcon className={`${s.chevron_icon} ${isOpen ? s.open : ''}`} />}
      </div>
      <Popper
        open={isOpen}
        anchorEl={rootRef.current}
        role={undefined}
        style={{ zIndex: 1, userSelect: 'none' }}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            timeout={100}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <div className={s.menu_container}>
                  {menuTitle && <p className={s.menu_title}>{menuTitle}</p>}
                  {headerCheckBoxes && (
                    <div className={s.header_container}>
                      {headerCheckBoxes.map((item, index) => (
                        <div
                          className={s.option_container}
                          onClick={() => onChange(item)}
                          key={Array.isArray(item.id) ? item.id[index] : item.id}
                        >
                          <FormControlLabel
                            className={s.form_control}
                            control={<Checkbox checked={values.some((value) => value.id === item.id)} size="medium" />}
                            label={<TextEllipsis maxWidth="100%">{item.en_reason}</TextEllipsis>}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className={s.options}>
                    {Array.isArray(options) &&
                      options.map((option, index) => (
                        <div
                          key={index}
                          className={`${s.option_container} ${disabled ? s.disabled : ''}`}
                          onClick={() => onChange(option)}
                        >
                          <FormControlLabel
                            className={s.form_control}
                            disabled={disabled}
                            control={<Checkbox checked={values.some((value) => value.id === option.id)} size="medium" />}
                            label={<TextEllipsis maxWidth="100%">{option.en_reason}</TextEllipsis>}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
