import { baseAxiosInstance } from 'axios-config';
import { PaginatedResponse } from 'types/common-types';
import { ProductType } from 'services/product-type.model';
import { buildQueryString } from 'utils/build-query-string';

export class ProductTypeService {
  public static async getAllProductTypes(search = '', skip = 0, take = 0) {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<PaginatedResponse<ProductType[]>>(`/product-types/all?${queryParams}`);
    return data;
  }

  public static async createProductType(name: string) {
    const { data } = await baseAxiosInstance.post<ProductType>('/product-types/create', { name });
    return data;
  }

  public static async deleteProductType(productTypeId: string) {
    return baseAxiosInstance.delete(`/product-types/${productTypeId}`);
  }
}
