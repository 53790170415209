import { ColDef, ColGroupDef, GridState, SideBarDef } from 'ag-grid-community';
import FloatingFilter from 'pages/tasks/modules/floating-filter/floating-filter';
import { FilterSettingEnum, TasksFiltersEnum } from 'pages/tasks/types/types';
import { TaskTableModel } from 'services/task-table.model';
import { ProductionTaskService } from 'services/production-task.service';
import { UserService } from 'services/user.service';
import VersionCell from 'pages/tasks/components/cells/version-cell/version-cell';
import DateCell from 'pages/tasks/components/cells/date-cell/date-cell';
import ReportingPeriodCell from 'pages/tasks/components/cells/reporting-period-cell/reporting-period-cell';
import TaskPriorityCell from 'pages/tasks/components/cells/task-priority-cell/task-priority-cell';
import ProductionProgressCell from 'pages/tasks/components/cells/production-progress-cell/production-progress-cell';
import MoneyCell from 'pages/tasks/components/cells/money-cell/money-cell';
import DateTimeCell from 'pages/tasks/components/cells/date-time-cell/date-time-cell';
import IsFailedCell from 'pages/tasks/components/cells/is-failed-cell/is-failed-cell';
import TaskTypeCell from 'pages/tasks/components/cells/task-type-cell/task-type-cell';
import WarningCell from 'pages/tasks/components/cells/warning-cell/warning-cell';
import TimeCell from 'pages/tasks/components/cells/time-cell/time-cell';
import LinkCell from 'pages/tasks/components/cells/link-cell/link-cell';
import ProductionStatusCell from 'pages/tasks/components/cells/production-status-cell/production-status-cell';
import TaskStatusCell from 'pages/tasks/components/cells/task-status-cell/task-status-cell';
import IsInQueueCell from 'pages/tasks/components/cells/is-in-queue-cell/is-in-queue-cell';
import AssignmentTypeCell from 'pages/tasks/components/cells/assignment-type-cell/assignment-type-cell';
import AssigneeCell from 'pages/tasks/components/cells/assignee-cell/assignee-cell';
import ArrayCell from 'pages/tasks/components/cells/array-cell/array-cell';
import CheckboxCell from 'pages/tasks/components/cells/checkbox-cell/checkbox-cell';
import SelectAllCell from 'pages/tasks/components/cells/select-all-cell/select-all-cell';
import MoreActionsMenuCell from 'pages/tasks/components/cells/more-actions-menu-cell/more-actions-menu-cell';
import { UNASSIGNED } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import ArrayIdNameCell from 'pages/tasks/components/cells/array-id-name-cell/array-id-name-cell';
import AssignedAtCell from 'pages/tasks/components/cells/assigned-at-cell/assigned-at-cell';

export const agGridDefaultColumnDefinitions: ColDef = {
  hide: false,
  filter: true,
  editable: false,
  floatingFilter: true,
  enableCellChangeFlash: false,
  suppressHeaderFilterButton: true,
  suppressFloatingFilterButton: true,
  floatingFilterComponentParams: {
    showSearchInput: true,
  },
  // Prevent default ag-grid sorting
  comparator: () => 0,
  floatingFilterComponent: FloatingFilter,
};

export const sideBarConfig: SideBarDef = {
  defaultToolPanel: 'columns',
  toolPanels: [
    {
      width: 336,
      id: 'columns',
      iconKey: 'columns',
      labelKey: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressValues: true,
        suppressPivotMode: true,
        suppressRowGroups: true,
        contractColumnSelection: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
};

export const agGridDefaultColumnGroupDefinitions: ColGroupDef = {
  suppressHeaderContextMenu: true,
  // Required
  children: [],
};

export const agGridInitialState: GridState = {
  sideBar: {
    visible: true,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
      },
    ],
    openToolPanel: '',
    position: 'right',
  },
};

export const agGridGroupedColumnDefinitions: ColGroupDef<TaskTableModel>[] = [
  {
    suppressColumnsToolPanel: true,
    children: [
      {
        colId: 'all_select',
        filter: false,
        lockVisible: false,
        suppressHeaderMenuButton: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        minWidth: 50,
        maxWidth: 50,
        pinned: 'left',
        lockPinned: true,
        headerComponent: SelectAllCell,
        cellRenderer: CheckboxCell,
      },
    ],
  },

  {
    headerName: 'Product',
    children: [
      {
        field: 'product_name',
        colId: 'product_name',
        headerName: 'Product name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductName },
      },
      {
        field: 'product_version',
        colId: 'product_version',
        headerName: 'Product version',
        cellRenderer: VersionCell,
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductVersion },
      },
      {
        field: 'product_variant',
        colId: 'product_variant',

        headerName: 'Product variant',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductVariant },
      },
      {
        field: 'product_configuration',
        colId: 'product_configuration',
        headerName: 'Product configuration',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductConfiguration },
      },
      {
        field: 'root_product_name',
        colId: 'root_product_name',
        headerName: 'Root product name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductName },
      },
      {
        field: 'root_product_version',
        colId: 'root_product_version',
        headerName: 'Root product version',
        cellRenderer: VersionCell,
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductVersion },
      },
      {
        field: 'root_product_variant',
        colId: 'root_product_variant',
        headerName: 'Root product variant',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductVariant },
      },
      {
        field: 'root_product_configuration',
        colId: 'root_product_configuration',
        headerName: 'Root product configuration',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductConfiguration },
      },
    ],
  },
  {
    headerName: 'Production',
    children: [
      {
        field: 'production_key',
        colId: 'production_key',
        headerName: 'Production key',
        cellRenderer: LinkCell,
        cellRendererParams: { fieldKey: TasksFiltersEnum.ProductionKey },
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductionKey },
      },
      {
        field: 'production_status',
        colId: 'production_status',
        headerName: 'Production status',
        cellRenderer: ProductionStatusCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.ProductionStatus,
        },
      },
      {
        field: 'root_production_key',
        colId: 'root_production_key',
        headerName: 'Root production key',
        cellRenderer: LinkCell,
        cellRendererParams: { fieldKey: TasksFiltersEnum.RootProductionKey },
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductionKey },
      },
      {
        field: 'production_deadline',
        colId: 'production_deadline',
        headerName: 'Production deadline',
        cellRenderer: DateCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.ProductionDeadline,
        },
      },
      {
        field: 'production_progress',
        colId: 'production_progress',
        headerName: 'Production progress',
        cellRenderer: ProductionProgressCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'root_production_deadline',
        sort: 'asc',
        colId: 'root_production_deadline',
        headerName: 'Root production deadline',
        cellRenderer: DateCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.RootProductionDeadline,
        },
      },
    ],
  },
  {
    headerName: 'Order',
    children: [
      {
        field: 'client',
        colId: 'client',
        headerName: 'Client',
        floatingFilterComponentParams: {
          useSelectFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.Client,
        },
      },
      {
        field: 'order_key',
        colId: 'order_key',
        headerName: 'Order key',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.OrderKey },
      },
      {
        field: 'primary_client',
        colId: 'primary_client',
        headerName: 'Primary client',
        floatingFilterComponentParams: {
          useSelectFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.PrimaryClient,
        },
      },
      {
        field: 'external_order_number',
        colId: 'external_order_number',
        headerName: 'External order number',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ExternalOrderNumber },
      },
      {
        field: 'marketplace_order_number',
        colId: 'marketplace_order_number',
        headerName: 'Market place order number',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.MarketPlaceOrderNumber },
      },
    ],
  },
  {
    headerName: 'Task details',
    children: [
      {
        field: 'task_key',
        colId: 'task_key',
        headerName: 'Task key',
        cellRenderer: LinkCell,
        cellRendererParams: { fieldKey: TasksFiltersEnum.TaskKey },
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.TaskKey },
      },
      {
        field: 'task_name',
        colId: 'task_name',
        headerName: 'Task name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.TaskName },
      },
      {
        field: 'failed_at',
        colId: 'failed_at',
        headerName: 'Failed at',
        cellRenderer: ArrayCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.FailedAt,
        },
      },
      {
        field: 'is_failed',
        colId: 'is_failed',
        headerName: 'Is failed',
        cellRenderer: IsFailedCell,
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.IsFailed,
        },
      },
      {
        field: 'task_type',
        colId: 'task_type',
        headerName: 'Task type',
        cellRenderer: TaskTypeCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskType,
        },
      },
      {
        field: 'is_in_queue',
        colId: 'is_in_queue',
        cellRenderer: IsInQueueCell,
        headerName: 'Is “In queue”',
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.IsInQueue,
        },
      },
      {
        field: 'total_bonus',
        colId: 'total_bonus',
        headerName: 'Total bonus',
        cellRenderer: MoneyCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'status',
        colId: 'status',
        headerName: 'Task status',
        cellRenderer: TaskStatusCell,
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskStatus,
        },
      },
      {
        field: 'basic_reward',
        colId: 'basic_reward',
        headerName: 'Basic reward',
        cellRenderer: MoneyCell,
        cellRendererParams: { type: 'basic_reward' },
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'priority',
        colId: 'priority',
        headerName: 'Task priority',
        cellRenderer: TaskPriorityCell,
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskPriority,
        },
      },
      {
        field: 'reporting_period',
        colId: 'reporting_period',
        headerName: 'Reporting period',
        cellRenderer: ReportingPeriodCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.ReportingPeriod,
        },
      },
      {
        field: 'reason_for_failure',
        colId: 'reason_for_failure',
        headerName: 'Reason for failure',
        cellRenderer: ArrayCell,
        floatingFilterComponentParams: {
          useSearchFilter: true,
          includeUnassignedOption: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.ReasonForFailure,
          fetchOptionsFunction: ProductionTaskService.getFailReasons,
        },
      },
    ],
  },
  {
    headerName: 'Assignment details',
    children: [
      {
        field: 'assignee',
        colId: 'assignee',
        headerName: 'Assignee',
        cellRenderer: AssigneeCell,
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          includeUnassignedOption: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.Assignee,
          fetchOptionsFunction: UserService.getAllUsers,
        },
        cellStyle: {
          display: 'flex',
        },
      },
      {
        field: 'assignment_type',
        colId: 'assignment_type',
        headerName: 'Assignment type',
        cellRenderer: AssignmentTypeCell,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.AssigneeType,
        },
      },
      {
        field: 'assignee_position',
        colId: 'assignee_position',
        headerName: 'Assignee position',
        cellRenderer: ArrayIdNameCell,
        floatingFilterComponentParams: { useSelectFilter: true, fieldName: TasksFiltersEnum.AssigneePosition },
      },
      {
        field: 'assignee_department',
        colId: 'assignee_department',
        headerName: 'Assignee department',
        cellRenderer: ArrayIdNameCell,
        floatingFilterComponentParams: { useSelectFilter: true, fieldName: TasksFiltersEnum.AssigneeDepartment },
      },
    ],
  },
  {
    headerName: 'Time',
    children: [
      {
        field: 'time_limit',
        colId: 'time_limit',
        headerName: 'Time limit',
        cellRenderer: TimeCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'time_spent',
        colId: 'time_spent',
        headerName: 'Time spent',
        cellRenderer: TimeCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'assigned_at',
        colId: 'assigned_at',
        headerName: 'Assigned at',
        cellRenderer: AssignedAtCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'created_at',
        colId: 'created_at',
        headerName: 'Created at',
        cellRenderer: DateTimeCell,
        floatingFilterComponentParams: { showSearchInput: false },
      },
    ],
  },
  {
    headerName: 'Warnings',
    children: [
      {
        field: 'assignee_required',
        colId: 'assignee_required',
        headerName: 'Assignee required',
        sortable: false,
        cellRenderer: WarningCell,
        cellRendererParams: { tooltipText: 'Manual assignment for a task required' },
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.AssigneeRequired,
        },
      },
      {
        field: 'time_limit_exceeded',
        colId: 'time_limit_exceeded',
        headerName: 'Time limit exceeded',
        cellRenderer: WarningCell,
        cellRendererParams: { tooltipText: 'The task has exceeded its time limit' },
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TimeLimitExceeded,
        },
      },
    ],
  },
  {
    suppressColumnsToolPanel: true,
    children: [
      {
        width: 80,
        colId: 'more_actions_menu',
        headerName: '',
        filter: false,
        sortable: false,
        resizable: false,
        lockVisible: false,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        cellRenderer: MoreActionsMenuCell,
      },
    ],
  },
];

export const onlyDigitsOrEmptyStringRegex = /^\d*$/;

export const productVersionFilters = [TasksFiltersEnum.ProductVersion, TasksFiltersEnum.RootProductVersion];

export const valueOptionsSelectedOptions = {
  value: '',
  options: [],
  selectedOptions: [],
};

export const valueOptionsSelectedOptionsWithSetting = {
  value: '',
  options: [],
  selectedOptions: [],
  filterSetting: FilterSettingEnum.Contains,
};

export const TASKS_PER_PAGE = 25;

export const dateFilters = [
  TasksFiltersEnum.FailedAt,
  TasksFiltersEnum.ReportingPeriod,
  TasksFiltersEnum.ProductionDeadline,
  TasksFiltersEnum.RootProductionDeadline,
];

export const objectFilters = [TasksFiltersEnum.Assignee];

export const filtersWithOptions = [
  TasksFiltersEnum.TaskType,
  TasksFiltersEnum.TaskStatus,
  TasksFiltersEnum.TaskPriority,
  TasksFiltersEnum.AssigneeType,
  TasksFiltersEnum.ReasonForFailure,
  TasksFiltersEnum.ProductionStatus,
];

export const booleanFilters = [
  TasksFiltersEnum.IsFailed,
  TasksFiltersEnum.IsInQueue,
  TasksFiltersEnum.AssigneeRequired,
  TasksFiltersEnum.TimeLimitExceeded,
];

export const showCompletedFilters = [TasksFiltersEnum.ShowCompleted];

export const TASKS_FILTERS = [
  TasksFiltersEnum.Client,
  TasksFiltersEnum.TaskKey,
  TasksFiltersEnum.OrderKey,
  TasksFiltersEnum.IsFailed,
  TasksFiltersEnum.FailedAt,
  TasksFiltersEnum.TaskType,
  TasksFiltersEnum.Assignee,
  TasksFiltersEnum.TaskName,
  TasksFiltersEnum.IsInQueue,
  TasksFiltersEnum.TaskStatus,
  TasksFiltersEnum.ProductName,
  TasksFiltersEnum.TaskPriority,
  TasksFiltersEnum.AssigneeType,
  TasksFiltersEnum.PrimaryClient,
  TasksFiltersEnum.ProductionKey,
  TasksFiltersEnum.ProductVariant,
  TasksFiltersEnum.ProductVersion,
  TasksFiltersEnum.RootProductName,
  TasksFiltersEnum.ReportingPeriod,
  TasksFiltersEnum.ProductionStatus,
  TasksFiltersEnum.ReasonForFailure,
  TasksFiltersEnum.AssigneePosition,
  TasksFiltersEnum.AssigneeRequired,
  TasksFiltersEnum.RootProductionKey,
  TasksFiltersEnum.TimeLimitExceeded,
  TasksFiltersEnum.RootProductVersion,
  TasksFiltersEnum.RootProductVariant,
  TasksFiltersEnum.ProductionDeadline,
  TasksFiltersEnum.AssigneeDepartment,
  TasksFiltersEnum.ExternalOrderNumber,
  TasksFiltersEnum.ProductConfiguration,
  TasksFiltersEnum.RootProductionDeadline,
  TasksFiltersEnum.MarketPlaceOrderNumber,
  TasksFiltersEnum.RootProductConfiguration,
];

export const keepNullFields = [TasksFiltersEnum.Client, TasksFiltersEnum.PrimaryClient];

export const TASKS_FIELDS = [
  'client',
  'status',
  'priority',
  'task_key',
  'assignee',
  'order_key',
  'task_name',
  'task_type',
  'is_failed',
  'failed_at',
  'time_limit',
  'time_spent',
  'created_at',
  'total_bonus',
  'is_in_queue',
  'assigned_at',
  'product_name',
  'basic_reward',
  'production_key',
  'primary_client',
  'product_version',
  'product_variant',
  'assignment_type',
  'reporting_period',
  'assignee_position',
  'assignee_required',
  'production_status',
  'root_product_name',
  'reason_for_failure',
  'root_production_key',
  'production_deadline',
  'production_progress',
  'assignee_department',
  'time_limit_exceeded',
  'root_product_version',
  'root_product_variant',
  'product_configuration',
  'external_order_number',
  'root_production_deadline',
  'marketplace_order_number',
  'root_product_configuration',
];

export const COLUMNS_FIELDS_DEFAULT = [
  // Product
  { colId: 'root_product_name', hide: false },
  { colId: 'root_product_version', hide: false },
  { colId: 'root_product_configuration', hide: false },
  { colId: 'root_product_variant', hide: false },
  { colId: 'product_name', hide: false },
  { colId: 'product_version', hide: false },
  { colId: 'product_configuration', hide: false },
  { colId: 'product_variant', hide: false },
  // Production
  { colId: 'production_key', hide: false },
  { colId: 'root_production_key', hide: false },
  { colId: 'production_deadline', hide: false },
  { colId: 'root_production_deadline', hide: false },
  { colId: 'production_status', hide: false },
  { colId: 'production_progress', hide: false },
  // Order
  { colId: 'order_key', hide: false },
  { colId: 'external_order_number', hide: false },
  { colId: 'marketplace_order_number', hide: false },
  { colId: 'client', hide: false },
  { colId: 'primary_client', hide: false },
  // Task details
  { colId: 'task_name', hide: false },
  { colId: 'task_key', hide: false },
  { colId: 'status', hide: false },
  { colId: 'priority', hide: false },
  { colId: 'task_type', hide: false },
  { colId: 'basic_reward', hide: false },
  { colId: 'total_bonus', hide: false },
  { colId: 'reporting_period', hide: false },
  { colId: 'is_in_queue', hide: false },
  { colId: 'is_failed', hide: false },
  { colId: 'reason_for_failure', hide: false },
  { colId: 'failed_at', hide: false },

  // Time
  { colId: 'time_limit', hide: false },
  { colId: 'time_spent', hide: false },
  { colId: 'assigned_at', hide: false },
  { colId: 'created_at', hide: false },
  // Assignment details
  { colId: 'assignee', hide: false },
  { colId: 'assignee_position', hide: false },
  { colId: 'assignee_department', hide: false },
  { colId: 'assignment_type', hide: false },
  // Warnings
  { colId: 'time_limit_exceeded', hide: false },
  { colId: 'assignee_required', hide: false },
];

export const unassignedOption = {
  id: UNASSIGNED,
  name: 'Unassigned',
  avatar: '',
  lastName: '',
  firstName: '',
};
