import { baseAxiosInstance } from 'axios-config';
import {
  AssignUserResponse,
  GetTaskAssignmentsParams,
  UpdateMultipleTaskAssignmentsRequestBody,
} from 'services/task-assigment.model';
import { TaskAssignmentResponse } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';

export class TaskAssigmentService {
  public static async assignUser(user_id: string, slot_id: string) {
    const { data } = await baseAxiosInstance.post<AssignUserResponse>(`/task-assignment/assign`, {
      user_id,
      slot_id,
    });
    return data;
  }

  public static async unassignUser(slot_id: string) {
    await baseAxiosInstance.post(`/task-assignment/unassign/${slot_id}`);
  }

  public static async updateMultipleTaskAssignments(body: UpdateMultipleTaskAssignmentsRequestBody) {
    const { data } = await baseAxiosInstance.patch(`/task-assignment/bulk`, body);
    return data;
  }

  static async getTaskAssignments({ task_id, skip, take, include = ['user'] }: GetTaskAssignmentsParams) {
    const { data } = await baseAxiosInstance.get<PaginateResponse<TaskAssignmentResponse>>('/task-assignment', {
      params: { task_id, skip, take, include },
    });

    return data;
  }
}
