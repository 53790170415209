import {
  TaskResponsibilityCreateRequest,
  TaskResponsibilityUpdateRequest,
  SuggestedUsersResponse,
} from 'services/task-responsibility.model';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { UserShortModel } from 'services/user.model';
import { PaginatedResponse } from 'types/common-types';
import { UserStatusEnum } from 'types/status-enums';
import { buildQueryString } from 'utils/build-query-string';
import { TaskResponsibilityModel } from './production-task.model';
import { baseAxiosInstance } from '../axios-config';

export class TaskResponsibilityService {
  public static async getSuggestedUsers({
    search,
    status,
    skip = 0,
    responsibilityId,
    take = PAGE_SIZE,
  }: {
    skip: number;
    take: number;
    search?: string;
    responsibilityId: string;
    status?: UserStatusEnum[];
  }) {
    const queryParams = buildQueryString({ search, skip, take, status });

    const { data } = await baseAxiosInstance.get<SuggestedUsersResponse<UserShortModel[]>>(
      `/task-responsibility/suggested-users/${responsibilityId}?${queryParams}`,
    );

    return data;
  }

  public static async getOtherUsers({
    search,
    status,
    skip = 0,
    responsibilityId,
    take = PAGE_SIZE,
  }: {
    skip: number;
    take: number;
    search?: string;
    responsibilityId: string;
    status?: UserStatusEnum[];
  }) {
    const queryParams = buildQueryString({ search, skip, take, status });

    const { data } = await baseAxiosInstance.get<PaginatedResponse<UserShortModel[]>>(
      `/task-responsibility/other-users/${responsibilityId}?${queryParams}`,
    );

    return data;
  }

  public static async createResponsibility(dto: TaskResponsibilityCreateRequest) {
    const { data } = await baseAxiosInstance.post<TaskResponsibilityModel>('/task-responsibility/create', dto);
    return data;
  }

  public static async deleteResponsibility(id: string) {
    const { data } = await baseAxiosInstance.delete<TaskResponsibilityModel>(`/task-responsibility/${id}`);
    return data;
  }

  public static async updateResponsibility(id: string, dto: TaskResponsibilityUpdateRequest) {
    const { data } = await baseAxiosInstance.put<TaskResponsibilityModel>(`/task-responsibility/${id}`, dto);
    return data;
  }
}
