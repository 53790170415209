export const formatToStringArray = (array: any[], field: string): string[] => {
  const isStringArray = array.every((item) => typeof item === 'string');
  if (isStringArray) return array;

  const isArrayValid = array.every((item) => item && Object.prototype.hasOwnProperty.call(item, field));

  if (!isArrayValid) return [];

  return array.map((item) => item[field].toString());
};
