import { FC, MouseEvent } from 'react';
import { CrossIcon } from 'icons/cross';
import { CheckIcon2 } from 'icons/check-icon-2';
import Button from 'components/button/button';
import s from './input-buttons.module.scss';

type OwnProps = {
  isLoading?: boolean;
  buttonSize?: number;
  isConfirmDisabled?: boolean;
  onCancelClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirmClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancelMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirmMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const InputButtons: FC<OwnProps> = ({
  isLoading,
  buttonSize = 28,
  isConfirmDisabled,
  onCancelClick,
  onConfirmClick,
  onCancelMouseDown,
  onConfirmMouseDown,
}) => {
  return (
    <div className={s.container}>
      <Button
        size="XXS"
        color="white"
        shape="square"
        variant="contained"
        loading={isLoading}
        className={s.action_button}
        disabled={isConfirmDisabled}
        data-disabled={isConfirmDisabled}
        style={{ height: buttonSize, width: buttonSize }}
        onClickCapture={(event) => {
          event.stopPropagation();
          if (!isConfirmDisabled && onConfirmClick) onConfirmClick(event);
        }}
        onMouseDownCapture={(event) => {
          event.stopPropagation();
          if (!isConfirmDisabled && onConfirmMouseDown) onConfirmMouseDown(event);
        }}
      >
        <CheckIcon2 />
      </Button>
      <Button
        size="XXS"
        color="white"
        shape="square"
        variant="contained"
        iconStyle="custom_icon"
        className={s.action_button}
        style={{ height: buttonSize, width: buttonSize }}
        onClickCapture={(event) => {
          event.stopPropagation();
          if (onCancelClick) onCancelClick(event);
        }}
        onMouseDownCapture={(event) => {
          event.stopPropagation();
          if (onCancelMouseDown) onCancelMouseDown(event);
        }}
      >
        <CrossIcon />
      </Button>
    </div>
  );
};

export default InputButtons;
