import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { IdName, PaginationData } from 'types/common-types';
import { StateController } from 'state-controller';
import { OrdersService } from 'services/orders.service';
import { ClientService } from 'services/client.service';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { debounce } from 'utils/debounce';

export type EditClientModalArgs = {
  page?: Page;
  orderId: string;
  productName?: string;
  client: IdName | null;
  productionId?: string;
  productionKey?: string;
  isOpenFromOrder?: boolean;
};

export type EditClientModalState = {
  keyword: string;
  isOpen: boolean;
  orderId: string;
  page: Page | null;
  isLoading: boolean;
  isFetching: boolean;
  productName: string;
  initialData: IdName;
  productionId: string;
  productionKey: string;
  isOpenFromOrder: boolean;
  isValueChanged: boolean;
  paginationClients: PaginationData;
  client: {
    isLoading: boolean;
    value: IdName | null;
    options: IdName[];
  };
};

const defaultState: EditClientModalState = {
  page: null,
  orderId: '',
  keyword: '',
  isOpen: false,
  productName: '',
  isLoading: false,
  productionId: '',
  isFetching: false,
  productionKey: '',
  initialData: {
    id: '',
    name: '',
  },
  client: {
    isLoading: false,
    value: null,
    options: [],
  },
  paginationClients: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  isOpenFromOrder: false,
  isValueChanged: false,
};

const stateController = new StateController<EditClientModalState>('EDIT_CLIENT_MODAL', defaultState);

export class EditClientActions {
  public static openModal({
    orderId,
    isOpenFromOrder = false,
    productName,
    productionKey,
    client,
    page,
    productionId,
  }: EditClientModalArgs) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      try {
        dispatch(stateController.setState({ isFetching: true }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            page,
            productionId,
            productName,
            isOpen: true,
            orderId,
            productionKey,
            isOpenFromOrder,
            initialData: {
              id: client?.id || '',
              name: client?.name || '',
            },
            client: {
              ...prev.client,
              value: { id: client?.id || '', name: client?.name || '' },
            },
          })),
        );
        dispatch(EditClientActions.initClients());
      } finally {
        dispatch(stateController.setState({ isFetching: false }));
      }
    };
  }

  public static initClients() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: true,
            },
          })),
        );

        const { data, meta } = await ClientService.getAllClients();

        const options = data.map((client) => ({ id: client.id, name: client.name }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationClients: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            client: {
              ...prev.client,
              options,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchClients(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationClients: {
              ...prev.paginationClients,
              next: 0,
            },
          })),
        );
      }
      const { paginationClients } = getState().production.editClientModal;
      if (isScroll && paginationClients.currentPage >= paginationClients.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await ClientService.getAllClients(
            value.trim(),
            paginationClients.next || undefined,
            paginationClients.perPage,
          );
          const options = data.map((order) => ({ id: order.id, name: order.name }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              client: {
                ...prev.client,
                options: isScroll ? [...prev.client.options, ...options] : options,
                isLoading: false,
              },
              paginationClients: {
                ...prev.paginationClients,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { orderId, client, page, productionId } = getState().production.editClientModal;

        if (client.value?.id === 'new') {
          const NewClientBody = {
            name: client.value?.name,
          };
          const { data } = await ClientService.createClient(NewClientBody);

          const updateBody = {
            client_id: data.id,
          };
          await OrdersService.updateOrder(orderId, updateBody);
        } else {
          const body = {
            client_id: client.value?.id,
          };
          await OrdersService.updateOrder(orderId, body);
        }

        if (page === Page.InfoDropdownWorkflow) {
          await loadData(productionId);
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }
        if (page === Page.InfoDropdownProduction) {
          await loadData(productionId);
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
              resetOpenedProductionGroups: true,
            }),
          );
        }
        if (page === Page.Production) {
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
              resetOpenedProductionGroups: true,
            }),
          );
        }
        if (page === Page.Workflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        dispatch(EditClientActions.closeModal());
        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static switchShowWarning() {
    return async (dispatch, getState: () => AppState) => {
      const { initialData, client } = getState().production.editClientModal;
      if (initialData.id !== client.value?.id) {
        dispatch(stateController.setState({ isValueChanged: true }));
      } else {
        dispatch(stateController.setState({ isValueChanged: false }));
      }
    };
  }

  public static onValueClientChange(value: EditClientModalState['client']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          client: {
            ...prev.client,
            value,
          },
        })),
      );
      dispatch(EditClientActions.switchShowWarning());
    };
  }
}

export class EditClientSelectors {
  public static isSaveDisabled(state: AppState) {
    const { isValueChanged } = state.production.editClientModal;
    const isNameEmpty = !state.production.editClientModal.client.value?.name;

    return !isValueChanged || isNameEmpty;
  }
}

export const reducer = stateController.getReducer();
