import { TabDownIcon } from 'icons/tab-down';
import MenuItem from '@mui/material/MenuItem';
import { ReactNode, useCallback } from 'react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import s from './select.module.scss';

type FontSize = 'small' | 'medium';

export type Props<T> = SelectProps<any> & {
  options: T[];
  className?: string;
  [key: string]: any;
  fontSize?: FontSize;
  emptyValue?: string;
  leftIcon?: ReactNode;
  hideMenuIcon?: boolean;
  value: string | string[];
  menuItemClassName?: string;
  minMenuWidth?: number | null;
  onChange: (value: string) => void;
  renderOption?: (option: T) => JSX.Element;
};

function SelectComponent<T extends { value: string; text?: string }>({
  options,
  leftIcon,
  value = '',
  emptyValue,
  hideMenuIcon,
  className = '',
  minMenuWidth = 0,
  fontSize = 'medium',
  menuItemClassName = '',
  onChange,
  renderOption,
  ...rest
}: Props<T>) {
  const renderValue = useCallback(
    (val: string | string[]) => {
      if ((!val || !val.length) && rest.placeholder) {
        return (
          <div className={fontSize === 'medium' ? s.placeholder : s.placeholder_small}>
            {leftIcon} {rest.placeholder}
          </div>
        );
      }

      let valueNormalized;
      if (rest.multiple && Array.isArray(val)) {
        valueNormalized = options
          .filter((option) => val.includes(option.value))
          .map((item) => item.text || item.value)
          .join(', ');
      } else {
        valueNormalized =
          options.find((option) => option.value === val)?.text || options.find((option) => option.value === val)?.value;
      }

      return valueNormalized;
    },
    [rest.multiple, rest.placeholder, options, value],
  );

  return (
    <Select<any>
      value={value}
      IconComponent={TabDownIcon}
      MenuProps={{
        className: s.menu,
        PaperProps: { sx: minMenuWidth ? { minWidth: `${minMenuWidth}px !important` } : {} },
      }}
      data-hide-menu-icon={hideMenuIcon}
      className={`${className} ${s.input}`}
      renderValue={rest.placeholder ? renderValue : undefined}
      displayEmpty={rest.displayEmpty ? rest.displayEmpty : false}
      onChange={(event: SelectChangeEvent<string>) => onChange(event.target.value)}
      {...(rest as any)}
    >
      {emptyValue !== '' && (
        <MenuItem value="">
          <div className={`${s.placeholder} ${rest.disabled ? s.disabled : ''}`}>{emptyValue}</div>
        </MenuItem>
      )}
      {!options?.length && (
        <MenuItem value="" disabled>
          No options
        </MenuItem>
      )}
      {options.map((item, index) => {
        if (renderOption) return renderOption(item);
        return (
          <MenuItem className={menuItemClassName && menuItemClassName} value={item.value} key={`${item.value}-${index}`}>
            {item.text || item.value}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default SelectComponent;
