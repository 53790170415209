import { UserStatusEnum } from 'types/status-enums';
import { UserShortModel } from 'services/user.model';
import { PaginatedResponse } from 'types/common-types';
import { SuggestedUsersResponse } from 'services/task-responsibility.model';
import { TaskResponsibilityService } from 'services/task-responsibility.service';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';

export const getUsersForTask = async (
  responsibilityId: string,
  otherUsers: UserShortModel[],
  suggestedUsers: UserShortModel[],
  keyword?: string,
  isKeywordChanged?: boolean,
  getOtherUsers?: (
    responsibilityId: string,
    skip?: number,
    take?: number,
    keyword?: string,
  ) => Promise<PaginatedResponse<UserShortModel[]>>,
  getSuggestedUsers?: (
    responsibilityId: string,
    skip?: number,
    take?: number,
    keyword?: string,
  ) => Promise<SuggestedUsersResponse<UserShortModel[]>>,
) => {
  let isOtherUsersLast = false;
  let isSuggestedUsersLast = false;
  let isAllUsersAreSuggested = false;
  let otherUsersData: PaginatedResponse<UserShortModel[]> | undefined;
  let suggestedUsersData: SuggestedUsersResponse<UserShortModel[]> | undefined;

  if (isKeywordChanged || !otherUsers.length) {
    const skip = isKeywordChanged ? 0 : suggestedUsers.length;
    const take = PAGE_SIZE;

    if (getSuggestedUsers) {
      suggestedUsersData = await getSuggestedUsers(responsibilityId, skip, take, keyword);
    } else {
      suggestedUsersData = await TaskResponsibilityService.getSuggestedUsers({
        skip,
        take,
        search: keyword,
        responsibilityId,
        status: [UserStatusEnum.Active],
      });
    }

    if (suggestedUsersData.meta.currentPage === suggestedUsersData.meta.lastPage || suggestedUsersData.meta.total === 0) {
      isSuggestedUsersLast = true;
    }
    if (suggestedUsersData.all_users_are_suggested) {
      isAllUsersAreSuggested = true;
    }
  }

  if ((suggestedUsersData?.data?.length || 0) < PAGE_SIZE || otherUsers.length) {
    const skip = isKeywordChanged ? 0 : otherUsers.length;
    const take = (suggestedUsersData?.data?.length && PAGE_SIZE - suggestedUsersData.data.length) || PAGE_SIZE;

    if (getOtherUsers) {
      otherUsersData = await getOtherUsers(responsibilityId, skip, take, keyword);
    } else {
      otherUsersData = await TaskResponsibilityService.getOtherUsers({
        skip,
        take,
        search: keyword,
        responsibilityId,
        status: [UserStatusEnum.Active],
      });
    }

    if (otherUsersData.meta.currentPage === otherUsersData.meta.lastPage || otherUsersData.meta.total === 0) {
      isOtherUsersLast = true;
    }
  }

  return {
    suggestedUsersData: suggestedUsersData?.data || [],
    otherUsersData: otherUsersData?.data || [],
    isSuggestedUsersLast,
    isOtherUsersLast,
    isAllUsersAreSuggested,
  };
};
