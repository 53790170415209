import FireMinusIcon from 'icons/fire-minus';
import { WarningColorIcon } from 'icons/warning-color';
import { MODALS } from 'modules/root-modals/modals';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { GetStateFunction } from 'redux/store';
import { ProductConfiguration } from 'services/product-configurations.model';
import { ProductPreviewInfoResponse, ProductVersion } from 'services/products.model';
import { ProductsService } from 'services/products.service';
import { StateController } from 'state-controller';
import { versionName } from 'utils/version-name';
import { Actions as ProductVersionsActions } from 'pages/product-flow/pages/product/controllers/product-version.controller';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { notify } from 'notifications';
import { ActiveTickIcon } from 'icons/active-tick';
import { WorkflowTemplatesService } from 'services/workflow-templates.service';
import { Actions as ProductConfigurationController } from './product-configurations.controller';
import s from '../product.module.scss';

export type PreviewProduct = ProductPreviewInfoResponse & {
  versions: ProductVersion[];
};

export type ProductState = {
  isOpen: boolean;
  isLoading: boolean;
  product: PreviewProduct;
  active_configuration: ProductConfiguration;
};

const defaultState: ProductState = {
  isOpen: false,
  isLoading: false,
  product: {
    category_path: [],
    id: '',
    product_configurations: [],
    is_active: false,
    product_meta: {
      id: '',
      name: '',
      product_category: { id: '', name: '' },
      product_type: { id: '', name: '' },
      product_vendor: [{ id: '', name: '' }],
      product_tag_relations: [{ id: '', tag: { id: '', name: '' } }],
    },
    published_at: '',
    version: '',
    versions: [],
    is_main_version: false,
    in_production_count: 0,
  },
  active_configuration: {
    id: '',
    description: '',
    name: '',
    order: 0,
    sku: '',
    product_configuration_parameters: [],
    product_configuration_files: [],
    product_configuration_photos: [],
    product_variants: [],
    workflowTemplates: [],
    breadcrumbs: [],
    is_active: true,
  },
};

const stateController = new StateController<ProductState>('PRODUCT_PREVIEW', defaultState);

export class Actions {
  public static open(id: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
          isLoading: true,
          product: {
            ...prev.product,
            id,
          },
        })),
      );
      try {
        const { version, is_main_version, published_at, product_configurations, in_production_count, ...product } =
          await ProductsService.getProductPreviewDetailedInfoById(id);

        const versionData: ProductVersion = {
          id,
          is_main_version,
          is_published: true,
          published_at,
          version: versionName(version),
          is_active: product.is_active,
          in_production_count,
        };

        dispatch(
          stateController.setState({
            product: {
              ...product,
              product_configurations,
              is_main_version,
              version,
              published_at,
              in_production_count,
              versions: [versionData],
            },
            active_configuration: product_configurations.length ? product_configurations[0] : defaultState.active_configuration,
          }),
        );
      } catch (err) {
        dispatch(
          stateController.setState({
            product: {
              ...defaultState.product,
              id,
            },
          }),
        );
        throw err;
      } finally {
        dispatch(
          stateController.setState({
            isLoading: false,
          }),
        );
      }
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static initConfigurationTab(id: string) {
    return (dispatch, getState: GetStateFunction) => {
      try {
        const activeConfiguration = getState().product.product_preview.product.product_configurations.find(
          (item) => item.id === id,
        );

        if (!activeConfiguration) throw new Error('activeCategoryId is undefined');

        dispatch(
          stateController.setState({
            active_configuration: {
              ...activeConfiguration,
            },
          }),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static makeMainVersion(id: string) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        await ProductsService.updateMainVersion(id);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              is_main_version: true,
              versions: [
                {
                  ...prev.product.versions[0],
                  is_main_version: true,
                },
              ],
            },
          })),
        );
        dispatch(ProductVersionsActions.updateVersionsState(id, 'set_main'));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static deleteVersion(id: string) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        await ProductsService.deleteProduct(id);
        dispatch(ProductVersionsActions.updateVersionsState(id, 'delete'));
        dispatch(stateController.setState({ ...defaultState }));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static openMakeMainVersionConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Зробити головною версію?',
            text: (
              <>
                Ви впевнені, що хочете зробити головною версію <strong>{name}</strong>?
              </>
            ),
            icon: <WarningColorIcon />,
            backgroundColor: '#FCE8C0',
            withCloseButton: false,
            actionText: 'Confirm',
            action: () => dispatch(Actions.makeMainVersion(id)),
          },
        }),
      );
    };
  }

  public static openDeleteConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Видалити версію?',
            text: (
              <>
                Ви впевнені, що хочете видалити версію <strong>{name}</strong>?
              </>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.deleteVersion(id)),
          },
        }),
      );
    };
  }

  public static changeConfigurationData(id: string, data: Partial<ProductConfiguration>) {
    return (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_preview.product;
      const mapped = product_configurations.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...data,
          };
        }
        return item;
      });
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configurations: mapped,
          active_configuration: { ...prev.active_configuration, ...data },
        })),
      );
    };
  }

  public static updateIsActiveProduct() {
    return (dispatch) =>
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            is_active: !prev.product.is_active,
          },
        })),
      );
  }

  public static openChangeIsActiveConfirmationModal(configurationId: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      try {
        const { name: productName } = getState().product.product_preview.product.product_meta;
        const { id: productId, version: productVersion } = getState().product.product_preview.product;
        const configurationName = getState().product.product_preview.product.product_configurations.find(
          (c) => c.id === configurationId,
        );

        if (!configurationName) throw new Error('configurationName is undefined');

        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: is_active ? 'Activate configuration?' : 'Inactivate configuration?',
              text: is_active ? (
                <div className={s.activate_product_container}>
                  <span className={s.text}>
                    Opening access to <strong>{productName}</strong> <strong>{configurationName}</strong> will allow this
                    configuration to be selected for production or used in{' '}
                    <span
                      className={s.related_product}
                      onClick={() => {
                        dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, productVersion));
                      }}
                    >
                      related products.
                    </span>
                  </span>
                  <span className={s.text}>
                    Are you sure you want to activate <strong>{configurationName}</strong>?
                  </span>
                </div>
              ) : (
                <div className={s.activate_product_container}>
                  <span className={s.text}>
                    Closing access to <strong>{productName}</strong> <strong>{configurationName}</strong> will prevent this
                    configuration from being selected for production or used in{' '}
                    <span
                      className={s.related_product}
                      onClick={() => {
                        dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, productVersion));
                      }}
                    >
                      related products.
                    </span>
                  </span>
                  <span className={s.text}>
                    Are you sure you want to inactivate <strong>{configurationName}</strong>?
                  </span>
                </div>
              ),
              icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
              backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
              withCloseButton: false,
              actionText: is_active ? 'Activate' : 'Inactivate',
              actionButtonColor: is_active ? 'primary' : 'error',
              action: () => dispatch(Actions.updateIsActiveConfigiuration(configurationId, is_active)),
            },
          }),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static updateIsActiveConfigiuration(configurationId: string, is_active: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      const { id } = getState().product.product_preview.product;
      const { product_configurations } = getState().product.product_preview.product;
      const originalConfiguration = product_configurations.find((configuration) => configuration.id === configurationId);

      try {
        await ProductConfigurationService.update(configurationId, {
          is_active,
          product_id: id,
        });

        const modifyConfigurations = product_configurations.map((configuration) => {
          if (configurationId === configuration.id) {
            return { ...configuration, is_active };
          }
          return configuration;
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              product_configurations: modifyConfigurations,
            },
            active_configuration: { ...originalConfiguration, is_active },
          })),
        );
        notify.success(is_active ? 'Successfully activated' : 'Successfully deactivated');
      } catch {
        if (originalConfiguration) dispatch(Actions.changeConfigurationData(configurationId, originalConfiguration));
        notify.error(is_active ? 'Activation error' : 'Deactivation error');
      }
    };
  }

  public static openChangeIsActiveConfirmationModalWorkflowTemplate(wokrflowTemplateId: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { name: productName } = getState().product.product_preview.product.product_meta;
      const { id: productId, version: productVersion } = getState().product.product_preview.product;
      const id = getState().product.product_preview.active_configuration.workflowTemplates?.find(
        (wt) => wt.id === wokrflowTemplateId,
      )?.id;
      const workflowName = getState().product.product_preview.active_configuration.workflowTemplates?.find(
        (wt) => wt.id === wokrflowTemplateId,
      )?.name;

      if (!id) throw new Error('id is undefined');

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: is_active ? 'Activate workflow template?' : 'Inactivate workflow template?',
            text: is_active ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will allow this
                  workflow to be selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, productVersion));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{workflowName}</strong>?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will prevent this
                  workflow from being selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, productVersion));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{workflowName}</strong> of the product?
                </span>
              </div>
            ),
            icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
            backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
            withCloseButton: false,
            actionText: is_active ? 'Activate' : 'Inactivate',
            actionButtonColor: is_active ? 'primary' : 'error',
            action: () => dispatch(Actions.updateIsActiveWorkflowTemplate(id, wokrflowTemplateId, is_active)),
          },
        }),
      );
    };
  }

  public static updateIsActiveWorkflowTemplate(configurationId: string, wokrflowTemplateId: string, is_active: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_preview.product;
      const originalConfiguration = product_configurations.find((configuration) => configuration.id === configurationId);

      try {
        await WorkflowTemplatesService.update(wokrflowTemplateId, {
          is_active,
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              product_configurations: prev.product.product_configurations.map((configuration) => {
                if (configurationId === configuration.id) {
                  return {
                    ...configuration,
                    workflowTemplates: configuration.workflowTemplates?.map((wt) => {
                      if (wokrflowTemplateId === wt.id) {
                        return { ...wt, is_active };
                      }
                      return wt;
                    }),
                  };
                }
                return configuration;
              }),
            },
            active_configuration: {
              ...prev.active_configuration,
              workflowTemplates: prev.active_configuration.workflowTemplates?.map((wt) => {
                if (wokrflowTemplateId === wt.id) {
                  return { ...wt, is_active };
                }
                return wt;
              }),
            },
          })),
        );
        notify.success(is_active ? 'Successfully activated' : 'Successfully deactivated');
      } catch {
        if (originalConfiguration) dispatch(Actions.changeConfigurationData(configurationId, originalConfiguration));
        notify.error(is_active ? 'Activation error' : 'Deactivation error');
      }
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
