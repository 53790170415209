import { IdName } from 'types/common-types';
import { TAKE_ITEMS } from 'constants/take-items';
import { PaginateResponse } from 'types/paginate-response';
import { buildQueryString } from 'utils/build-query-string';
import { baseAxiosInstance } from '../axios-config';
import { GetAllOrdersArgs, OrderCreateRequest, OrderData, OrdersAll, updateOrderRequestBody } from './orders.model';

export class OrdersService {
  public static async getAllOrders({
    search = '',
    skip = 0,
    take = 20,
    is_external_order_number = false,
    is_marketplace_order_number = false,
  }: GetAllOrdersArgs) {
    const queryParams = buildQueryString({ search, skip, take, is_external_order_number, is_marketplace_order_number });
    const { data } = await baseAxiosInstance.get<OrdersAll>(`/orders/all?${queryParams}`);
    return data;
  }

  public static async getOrderKeys(search = '', skip = 0, take = TAKE_ITEMS): Promise<PaginateResponse<IdName>> {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<PaginateResponse<IdName>>(`/orders/find-order-keys?${queryParams}`);
    return data;
  }

  public static async getExternalOrderNumbers(search = '', skip = 0, take = TAKE_ITEMS): Promise<PaginateResponse<IdName>> {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<PaginateResponse<IdName>>(`/orders/external-order-numbers?${queryParams}`);
    return data;
  }

  public static async getMarketplaceOrderNumbers(search = '', skip = 0, take = TAKE_ITEMS): Promise<PaginateResponse<IdName>> {
    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get<PaginateResponse<IdName>>(`/orders/marketplace-order-numbers?${queryParams}`);

    return data;
  }

  public static async updateOrder(id: string, body: updateOrderRequestBody): Promise<IdName[]> {
    const { data } = await baseAxiosInstance.put(`/orders/${id}`, body);
    return data;
  }

  public static async createOrder(newOrderData: OrderCreateRequest): Promise<OrderData> {
    const { data } = await baseAxiosInstance.post('/orders/create', newOrderData);
    return data;
  }

  public static async deleteOrder(id: string) {
    return baseAxiosInstance.delete(`/orders/${id}`);
  }
}
