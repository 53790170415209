import { IdName, IdNameIsActive, IdNameOrder, MetaT, SortOrderOption } from 'types/common-types';
import { ProductConfiguration } from 'services/product-configurations.model';
import { ReactNode } from 'react';
import { SortOptionsEnum } from 'pages/product-search/filter-enums';
import { Tag } from './product-tags.model';
import { ProductOption, ProductOptionValue } from './product-options.model';

export type ProductInfoResponse = {
  id: string;
  product_meta: ProductMeta;
  is_main_version: boolean;
  is_published: boolean;
  is_active: boolean;
  product_configurations: ProductConfigurationTab[];
  category_path: IdName[];
  version: string;
  published_products: ProductVersionResponse[];
  modified_at?: string;
};

export enum ProductDeleteReason {
  InUse = 'in_use',
  IsBeingEdited = 'is_being_edited',
  WasProduced = 'was_produced',
}

export type PublishedProduct = {
  id: string;
  version: number;
  is_active: boolean;
};

export type PublishedProductWithName = {
  name: string;
} & PublishedProduct;

export type ProductWithVersionData = {
  id: string;
  name: string;
  category_path: string;
  is_active: boolean;
  product_meta_id: string;
  published_products: PublishedProduct[];
};

export type ProductWithVersion = {
  data: ProductWithVersionData[];
  meta: MetaT;
};

export type ProductForSelect = {
  id: string;
  name: string;
  breadcrumbs: string;
  icon: ReactNode;
  is_active: boolean;
  product_meta_id?: string;
  published_products: PublishedProduct[];
};

export type ProductPreviewInfoResponse = {
  id: string;
  product_meta: ProductMeta;
  product_configurations: ProductConfiguration[];
  category_path: IdName[];
  version: string;
  published_at: string;
  is_main_version: boolean;
  is_active: boolean;
  in_production_count: number;
};

export type Product = Omit<ProductInfoResponse, 'published_products'> & {
  id: string;
  name: string;
  created_at: string;
  modified_at: string;
  modified_by: string;
  product_meta_id: string;
  published_at: string;
  published_by: string;
  is_published: boolean;
  is_draft: boolean;
  product_draft_id: string;
  published_products: number;
};

export type UniqueVariantsResponse = IdName[];

export type ProductVariant = {
  id: string;
  barcode: string;
  sku: string;
  is_active: boolean;
  configuration_photo_relation_id: string;
  product_configuration_id: string;
  variant_photo: ProductVariantPhoto;
  name: string;
};

export type ProductVariantForSelect = {
  id: string;
  is_active: boolean;
  name: string;
};

export type ProductParameters = {
  id: string;
  product_option: ProductOption;
  product_option_id: string;
  product_option_value: ProductOptionValue;
  product_option_value_id: string;
  product_variant_id: string;
};

export type ProductVariantPhoto = {
  id: string;
  product_variant_id?: string;
  link: string;
  name: string;
  content_type?: string;
  size?: number;
  is_show_by_default: boolean;
  is_deleted?: boolean;
};

export type ProductTagRelation = {
  id: string;
  product_meta_id?: string;
  product_tag?: IdName;
  product_tag_id?: string;
  tag: IdName;
};

export type ProductMetaFindResponse = {
  id: string;
  name: string;
  product_type: IdName;
  product_vendor: IdName;
};

export type ProductMeta = {
  id: string;
  name: string;
  is_available?: boolean;
  product_category: IdName;
  product_type: IdName;
  product_vendor: IdName[];
  product_tag_relations: ProductTagRelation[];
};

export type ProductData = {
  id: string;
  modified_at: string;
  modified_by: string;
  product_meta: ProductMeta;
  configurations?: number;
  variants?: number;
  workFlows?: number;
  tags: Tag[];
  category_path?: IdName[];
  is_draft?: boolean;
  is_published?: boolean;
  is_active: boolean;
  version: string;
  published_products?: number;
  product_configurations?: ProductConfiguration[];
};

export interface RelatedProductResponse {
  id: string;
  name: string;
  version: number;
  workflowTemplates: IdName;
}

export interface IsProductDeleteResponse {
  canBeDeleted: boolean;
  reasons: Reason[];
}

export interface Reason {
  reason: ProductDeleteReason;
  relatedWorkflowsCount?: number;
}

export type ProductDataPublishedProducts = ProductData & { published_products: number };
export type ProductDataWithProductVersions = {
  is_active: boolean;
  breadcrumbs: string;
  icon: ReactNode;
  id: string;
  name: string;
  versions: IdNameIsActive[];
};

export type ProductVersionResponse = {
  id: string;
  is_main_version: boolean;
  is_active: boolean;
  is_published: boolean;
  published_at: string;
  version: number;
  in_production_count: number;
};

export type ProductVersion = {
  id: string;
  version: string;
  is_published: boolean;
  is_active: boolean;
  published_at: string;
  is_main_version: boolean;
  in_production_count?: number;
};

export interface ProductWithBarcodes {
  id: string;
  name: string;
  sku: string;
  collection: IdName;
  avatar_image_url: null | string;
  flowExists: boolean;
  barcodes: ProductBarcode[];
}

export interface ProductBarcode {
  barcode: string;
  name: string;
  size: string;
  color: string;
}

export interface Pageable {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export type CreateProductData = {
  name: string;
  product_category_id: string | null;
};
export type UpdateProductMeta = {
  name?: string;
  product_category_id?: string;
  product_type_id?: string;
  product_vendor_id?: string;
  is_active?: boolean;
  is_draft?: boolean;
  is_published?: boolean;
};

export type UpdateProduct = {
  is_active?: boolean;
};

export type ProductConfigurationTab = IdNameOrder & { is_valid?: boolean; is_active?: boolean };

export type ProductForProductionVariant = {
  id: string;
  name: string;
  is_active: boolean;
  barcode: string;
  sku: string;
};

export type ProductForProductionWorkflow = {
  id: string;
  name: string;
  is_active: boolean;
};

export type ProductForProductionConfiguration = {
  id: string;
  name: string;
  is_active: boolean;
  variants: ProductForProductionVariant[];
  workflows: ProductForProductionWorkflow[];
};

export type ProductForProductionVersion = {
  id: string;
  name: string;
  is_active: boolean;
  configurations: ProductForProductionConfiguration[];
};

export type ProductForProduction = {
  workflow_template_item_id?: string;
  workflow_template_id?: string;
  source_id: string;
  is_active: boolean;
  product_name: string;
  type: string;
  vendor: string;
  versions: ProductForProductionVersion[];
};

export type BarcodeValidationModel = {
  is_valid: boolean;
  productId?: string;
  productName?: string;
};

export type FilterParams = {
  sku: string;
  skip: number;
  take: number;
  name: string;
  parameters: string;
  product_name: string;
  product_tag_id: string;
  publish_date_end: string;
  order_by: SortOptionsEnum;
  product_vendor_id: string;
  publish_date_start: string;
  product_category_id: string;
  last_update_date_end: string;
  last_update_date_start: string;
  order_direction: SortOrderOption;
  is_available: boolean | undefined;
  contains_draft: boolean | undefined;
};

export type ProductsByFilterData = {
  data: ProductDataPublishedProducts[];
  meta: MetaT;
};
