import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflow } from 'services/production-workflow.model';
import { ProductForSelect, ProductVariantForSelect, PublishedProductWithName } from 'services/products.model';
import { ProductConfigurationForSelect } from 'services/product-configurations.model';
import { ExternalOrderNumberForSelect, OrderClient, OrderKeyForSelect } from 'services/orders.model';
import { IdName, PaginationData } from 'types/common-types';
import { StateController } from 'state-controller';
import { ProductsService } from 'services/products.service';
import { Products2Icon } from 'icons/products-2';
import { OrdersService } from 'services/orders.service';
import { ClientService } from 'services/client.service';
import { GetStateFunction } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductVariantService } from 'services/product-variant.service';
import { versionName } from 'utils/version-name';
import { OrderProductionItemsService } from 'services/order-production-items.service';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductsLaunchModalActions } from 'pages/production/controllers/products-launch-modal-controller/products-launch-modal.controller';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { debounce } from 'utils/debounce';
import { notify } from 'notifications';

export type ProductionNewModalState = {
  count: number;
  isOpen: boolean;
  isOrderCreating: boolean;
  isOrderCreatingBeforeLaunch: boolean;
  paginationProducts: PaginationData;
  paginationOrderKey: PaginationData;
  paginationExternalOrderNumber: PaginationData;
  paginationClients: PaginationData;
  product: {
    isLoading: boolean;
    value: ProductForSelect | null;
    options: ProductForSelect[];
  };
  version: {
    isLoading: boolean;
    value: PublishedProductWithName | null;
    options: PublishedProductWithName[];
  };
  configuration: {
    isLoading: boolean;
    value: ProductConfigurationForSelect | null;
    options: ProductConfigurationForSelect[];
  };
  variant: {
    isLoading: boolean;
    value: ProductVariantForSelect | null;
    options: ProductVariantForSelect[];
  };
  orderKey: {
    isLoading: boolean;
    value: OrderKeyForSelect | null;
    options: OrderKeyForSelect[];
  };
  externalOrderNumber: {
    isLoading: boolean;
    value: ExternalOrderNumberForSelect | null;
    options: ExternalOrderNumberForSelect[];
  };
  orderClient: {
    isLoading: boolean;
    value: OrderClient | null;
    options: OrderClient[];
  };
  client: {
    isLoading: boolean;
    value: IdName | null;
    options: IdName[];
  };
};

const defaultState: ProductionNewModalState = {
  count: 1,
  isOpen: false,
  isOrderCreating: false,
  isOrderCreatingBeforeLaunch: false,
  paginationProducts: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  paginationOrderKey: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  paginationExternalOrderNumber: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  paginationClients: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  product: {
    isLoading: false,
    value: null,
    options: [],
  },
  version: {
    isLoading: false,
    value: null,
    options: [],
  },
  configuration: {
    isLoading: false,
    value: null,
    options: [],
  },
  variant: {
    isLoading: false,
    value: null,
    options: [],
  },
  orderKey: {
    isLoading: false,
    value: null,
    options: [],
  },
  externalOrderNumber: {
    isLoading: false,
    value: null,
    options: [],
  },
  orderClient: {
    isLoading: false,
    value: null,
    options: [],
  },
  client: {
    isLoading: false,
    value: null,
    options: [],
  },
};

const stateController = new StateController<ProductionNewModalState>('PRODUCTION_NEW_MODAL', defaultState);

export class ProductionNewModalActions {
  public static openModal() {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
        })),
      );
      dispatch(ProductionNewModalActions.initProductsWithVersions());
    };
  }

  public static initProductsWithVersions() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await ProductsService.getProductWithVersion();

        const options: ProductForSelect[] = data.map((product) => ({
          id: product.id,
          name: product.name,
          breadcrumbs: product.category_path,
          is_active: product.is_active,
          published_products: product.published_products,
          icon: <Products2Icon />,
        }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationProducts: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            product: {
              ...prev.product,
              options,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initOrderKey() {
    return async (dispatch, getState: GetStateFunction) => {
      const { options } = getState().production.productionNewModal.orderKey;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await OrdersService.getAllOrders({});

        const orderKeys = data.map((order) => ({
          id: order.id,
          name: order.order_key,
          client: order?.client?.name,
          client_id: order?.client?.id,
          external_order_number: order?.external_order_number,
          external_order_id: order?.external_order_id,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationOrderKey: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            orderKey: {
              ...prev.orderKey,
              options: orderKeys,
              value: prev.orderKey.value ?? null,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initExternalOrderNumber() {
    return async (dispatch, getState: GetStateFunction) => {
      const { options } = getState().production.productionNewModal.externalOrderNumber;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: true,
            },
          })),
        );
        const { meta, data } = await OrdersService.getAllOrders({ is_external_order_number: true });
        const externalOrderNumbers = data
          .map((order) => ({
            id: order.id,
            name: order.external_order_number,
            order_key: order.order_key,
            order_id: order.id,
            client: order?.client?.name,
            client_id: order?.client?.id,
          }))
          .filter((item) => !!item.name);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationExternalOrderNumber: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              options: externalOrderNumbers,
              value: prev.externalOrderNumber.value ?? null,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initClients() {
    return async (dispatch, getState: GetStateFunction) => {
      const { options, value } = getState().production.productionNewModal.client;
      if ((!options.length && value?.id === 'new') || options.length) return;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: true,
            },
          })),
        );

        const { data, meta } = await ClientService.getAllClients();

        const newOptions = data.map((order) => ({ id: order.id, name: order.name }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationClients: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            client: {
              ...prev.client,
              options: newOptions,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initProductConfigurations() {
    return async (dispatch, getState: GetStateFunction) => {
      const productId = getState().production.productionNewModal.version.value?.id;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: true,
            },
          })),
        );

        if (!productId) {
          throw new Error('Product id is undefined');
        }

        const productConfigurations = await ProductConfigurationService.getAll(productId);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              value: productConfigurations?.sort((config1, config2) => config1.order - config2.order)[0],
              options: productConfigurations,
              isLoading: false,
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static initProductVariants() {
    return async (dispatch, getState: GetStateFunction) => {
      const configurationId = getState().production.productionNewModal.configuration.value?.id;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: true,
            },
          })),
        );

        if (!configurationId) {
          throw new Error('Configuration id is undefined');
        }

        const productVariants = await ProductVariantService.getAll(configurationId);
        const options = productVariants
          .filter((i) => i.is_active === true)
          .map((variant) => ({
            id: variant.id,
            name: `${variant.name} - ${variant.sku} - ${variant.barcode}`,
            is_active: variant.is_active,
          }));
        const defaultVariant = options[0];
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              options,
              value: defaultVariant,
              isLoading: false,
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static loadMoreOrSearchOrderKey(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationOrderKey: {
              ...prev.paginationOrderKey,
              next: 0,
            },
          })),
        );
      }
      const { paginationOrderKey } = getState().production.productionNewModal;
      if (isScroll && paginationOrderKey.currentPage >= paginationOrderKey.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { meta, data } = await OrdersService.getAllOrders({
            search: value.trim(),
            skip: paginationOrderKey.next || undefined,
            take: paginationOrderKey.perPage,
          });
          const orderKeys = data.map((order) => ({
            id: order.id,
            name: order.order_key,
            client: order?.client?.name,
            client_id: order?.client?.id,
            external_order_number: order?.external_order_number,
            external_order_id: order?.external_order_id,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              orderKey: {
                ...prev.orderKey,
                options: isScroll ? [...prev.orderKey.options, ...orderKeys] : orderKeys,
                isLoading: false,
              },
              paginationOrderKey: {
                ...prev.paginationOrderKey,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchExternalOrderNumber(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationExternalOrderNumber: {
              ...prev.paginationExternalOrderNumber,
              next: 0,
            },
          })),
        );
      }
      const { paginationExternalOrderNumber } = getState().production.productionNewModal;
      if (isScroll && paginationExternalOrderNumber.currentPage >= paginationExternalOrderNumber.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { meta, data } = await OrdersService.getAllOrders({
            search: value.trim(),
            skip: paginationExternalOrderNumber.next || undefined,
            take: paginationExternalOrderNumber.perPage,
            is_external_order_number: true,
          });
          const externalOrderNumbers = data
            .map((order) => ({
              id: order.id,
              name: order.external_order_number,
              order_key: order.order_key,
              order_id: order.id,
              client: order?.client?.name,
              client_id: order?.client?.id,
            }))
            .filter((item) => !!item.name);

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              externalOrderNumber: {
                ...prev.externalOrderNumber,
                options: isScroll ? [...prev.externalOrderNumber.options, ...externalOrderNumbers] : externalOrderNumbers,
                isLoading: false,
              },
              paginationExternalOrderNumber: {
                ...prev.paginationExternalOrderNumber,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchClients(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationClients: {
              ...prev.paginationClients,
              next: 0,
            },
          })),
        );
      }
      const { paginationClients } = getState().production.productionNewModal;
      if (isScroll && paginationClients.currentPage >= paginationClients.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await ClientService.getAllClients(
            value.trim(),
            paginationClients.next || undefined,
            paginationClients.perPage,
          );
          const options = data.map((order) => ({ id: order.id, name: order.name }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              client: {
                ...prev.client,
                options: isScroll ? [...prev.client.options, ...options] : options,
                isLoading: false,
              },
              paginationClients: {
                ...prev.paginationClients,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchProductsWithVersions(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationProducts: {
              ...prev.paginationProducts,
              next: 0,
            },
          })),
        );
      }
      const { paginationProducts } = getState().production.productionNewModal;
      if (isScroll && paginationProducts.currentPage >= paginationProducts.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await ProductsService.getProductWithVersion(
            value.trim(),
            paginationProducts.next || undefined,
            paginationProducts.perPage,
          );
          const options: ProductForSelect[] = data.map((product) => ({
            id: product.id,
            name: product.name,
            breadcrumbs: product.category_path,
            is_active: product.is_active,
            published_products: product.published_products,
            icon: <Products2Icon />,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              product: {
                ...prev.product,
                options: isScroll ? [...prev.product.options, ...options] : options,
                isLoading: false,
              },
              paginationProducts: {
                ...prev.paginationProducts,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static onValueProductChange(value: ProductionNewModalState['product']['value']) {
    return (dispatch) => {
      try {
        const productVersions = value?.published_products.map((productVersion) => ({
          ...productVersion,
          name: versionName(productVersion.version),
        }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              value,
            },
            version: {
              ...prev.version,
              value: null,
              options: productVersions,
            },
            configuration: {
              ...prev.version,
              value: null,
              options: [],
            },
            variant: {
              ...prev.version,
              value: null,
              options: [],
            },
          })),
        );

        if (!productVersions?.[0]) {
          throw new Error('productVersion is undefined');
        }

        dispatch(ProductionNewModalActions.onValueVersionChange(productVersions[0]));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static onValueVersionChange(value: ProductionNewModalState['version']['value']) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          version: {
            ...prev.version,
            value,
          },
          configuration: {
            ...prev.version,
            value: null,
            options: [],
          },
          variant: {
            ...prev.version,
            value: null,
            options: [],
          },
        })),
      );
      await dispatch(ProductionNewModalActions.initProductConfigurations());
      dispatch(ProductionNewModalActions.initProductVariants());
    };
  }

  public static onValueConfigurationChange(value: ProductionNewModalState['configuration']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            value,
          },
          variant: {
            ...prev.version,
            value: null,
          },
        })),
      );
      dispatch(ProductionNewModalActions.initProductVariants());
    };
  }

  public static onValueVariantChange(value: ProductionNewModalState['variant']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          variant: {
            ...prev.variant,
            value,
          },
        })),
      );
    };
  }

  public static onValueOrderKeyChange(value: ProductionNewModalState['orderKey']['value']) {
    return (dispatch) => {
      const isExternalOrderNumberExist = value?.external_order_id && value?.external_order_number;
      const isClientExist = value?.client_id && value?.client;

      const chosenExternalOrderNumber = {
        id: value?.external_order_id,
        name: value?.external_order_number,
      } as ExternalOrderNumberForSelect;

      const chosenClient = {
        id: value?.client_id,
        name: value?.client,
      } as OrderClient;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: {
            ...prev.orderKey,
            value,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: isExternalOrderNumberExist ? chosenExternalOrderNumber : null,
          },
          client: {
            ...prev.client,
            value: isClientExist ? chosenClient : null,
          },
        })),
      );
    };
  }

  public static onValueExternalOrderNumberChange(value: ProductionNewModalState['externalOrderNumber']['value']) {
    return (dispatch) => {
      const isClientExist = Boolean(value?.client_id && value?.client);
      const isOrderKeyExist = Boolean(value?.order_id && value?.order_key);

      const chosenOrderKey = {
        id: value?.order_id,
        name: value?.order_key,
      } as OrderKeyForSelect;

      const chosenClient = {
        id: value?.client_id,
        name: value?.client,
      } as OrderClient;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value,
          },
          orderKey: {
            ...prev.orderKey,
            value: isOrderKeyExist ? chosenOrderKey : null,
          },
          client: {
            ...prev.client,
            value: isClientExist ? chosenClient : null,
          },
        })),
      );
    };
  }

  public static onValueClientChange(value: ProductionNewModalState['client']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          client: {
            ...prev.client,
            value,
          },
          orderKey: {
            ...prev.orderKey,
            value: null,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: null,
          },
        })),
      );
    };
  }

  public static onCountChange(value: number) {
    return (dispatch) => {
      dispatch(stateController.setState({ count: value }));
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static confirm(loadingKey: 'isOrderCreating' | 'isOrderCreatingBeforeLaunch') {
    return async (dispatch, getState: GetStateFunction) => {
      const {
        count,
        client,
        orderKey,
        externalOrderNumber,
        variant: { value: variantValue },
      } = getState().production.productionNewModal;

      try {
        if (loadingKey === 'isOrderCreating') {
          dispatch(stateController.setState({ isOrderCreating: true }));
        }

        if (loadingKey === 'isOrderCreatingBeforeLaunch') {
          dispatch(stateController.setState({ isOrderCreatingBeforeLaunch: true }));
        }

        let order_id = orderKey.value?.id || externalOrderNumber.value?.id;

        if (order_id === 'new' || !order_id) {
          const newOrder = await OrdersService.createOrder({
            ...(externalOrderNumber.value?.name ? { external_order_number: externalOrderNumber.value?.name } : {}),
            ...(client.value && client.value.name !== ''
              ? {
                  client: {
                    ...(client.value.id === 'new'
                      ? { name: client.value.name }
                      : { id: client.value.id, name: client.value.name }),
                  },
                }
              : {}),
          });
          order_id = newOrder.id;
        }

        if (!variantValue) {
          throw new Error('variantValue is undefined');
        }

        const productionItems = await OrderProductionItemsService.create({
          order_id,
          quantity: count,
          product_variant_id: variantValue.id,
        });
        const productionWorkflows = await ProductionWorkflowService.create({
          order_id,
          product_variant_id: variantValue.id,
          external_order_number: externalOrderNumber.value?.name || '',
          order_production_item_ids: productionItems.map((item) => item.id),
        });

        await dispatch(ProductionFiltersActions.getProductionsByFilter({ showFetchEffect: false, resetSkipPreserveTake: true }));
        dispatch(stateController.setState(defaultState));

        return {
          productVariantId: variantValue?.id,
          productionWorkflows,
        };
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        if (loadingKey === 'isOrderCreating') {
          dispatch(stateController.setState({ isOrderCreating: false }));
        }

        if (loadingKey === 'isOrderCreatingBeforeLaunch') {
          dispatch(stateController.setState({ isOrderCreatingBeforeLaunch: false }));
        }
      }
    };
  }

  public static confirmAndLaunch() {
    return async (dispatch) => {
      const { productionWorkflows, productVariantId }: { productionWorkflows: ProductionWorkflow[]; productVariantId: string } =
        await dispatch(ProductionNewModalActions.confirm('isOrderCreatingBeforeLaunch'));

      if (productionWorkflows.length === 1) {
        dispatch(
          ProductsLaunchModalActions.openModal({
            productVariantId,
            openedFrom: LocationTheProductionStatusIsChangingFrom.NewProductionModal,
            productionIdToLaunch: productionWorkflows[0].id,
          }),
        );
        return;
      }

      dispatch(
        ProductsLaunchModalActions.openMultiLaunchModal(
          productionWorkflows,
          LocationTheProductionStatusIsChangingFrom.NewProductionModal,
        ),
      );
    };
  }
}

export const reducer = stateController.getReducer();
